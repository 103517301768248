import styles from "./Form.module.css";
import FormCard from "./FormCard";
import { useNavigate } from "react-router-dom";

interface SuccessProps {
  success: boolean;
}

export default function Success({ success }: SuccessProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <FormCard success={success} />
        <button className={styles.ok} onClick={() => navigate("/")}>
          U REDU
        </button>
      </div>
    </div>
  );
}
