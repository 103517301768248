interface Data {
  date: string;
  title: string;
  image: string;
  description: string[];
}

export const NovostiData: Data[] = [
  {
    date: "1.11.2023.",
    title:
      "Novi hibridni filer na tržištu HArmonyCA, dostupan i u našoj poliklinici",
    image: "test2.png",
    description: [
      `HArmonyCa je novi hibridni filer i biostimulator na tržištu, za poboljšanje strukture lica i kvalitete kože. ”HA“ u HArmonyCa™ označava hijaluronsku kiselinu, jedan od ključnih sastojaka ovog dermalnog filera. “Ca“ na kraju naziva označava kalcij, glavnu komponentu drugog ključnog sastojka, kalcijevog hidroksiapatita. Spajanjem ovih sastojaka u jednom proizvodu, Allergan je stvorio harmoniju ili ravnotežu, što se odražava u nazivu “HArmonyCa“. Jedna od izvrsnih stvari kod HArmonyCa™ je što možete vidjeti rezultate odmah nakon tretmana. Komponenta hijaluronske kiseline pruža trenutačni volumen i potporu koži, može zagladiti bore i linije te dati licu mlađi izgled. Međutim, prednosti komponente kalcijevog hidroksiapatita su postepenije.
Kako proizvod potiče proizvodnju kolagena u koži, rezultati se nastavljaju poboljšavati tijekom vremena, rezultirajući čvršćom i zategnutijom kožom. Rezultati mogu trajati do 2 godine, ovisno o pojedincu i području tretmana.`,
      `Navedeni proizvod dostupan je u našoj poliklinici i možete se naručiti pozivom na broj <strong><a href="tel:+385993414555" style="text-decoration:none;color:black">(+385) 99 341 4555</a></strong>.`,
    ],
  },
  {
    date: "1.6.2023.",
    title: "Novo u ponudi!",
    image: "pbserum.jpeg",
    description: [
      `Pbserum sadrzi kombinaciju enzima lipaze, liaze i kolagenaze te s njim možemo izvrsno djelovati na problematiku lokaliziranih masnih naslaga, celulita i ožiljaka. Kao takav je idealan za tretman podbratka, trbuha te lica.
      Ukoliko želite znati jeste li baš vi kandidat za navedene tretmane, obratite nam se s povjerenjem!`,
    ],
  },
];
