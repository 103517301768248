import styles from "./Cookie.module.css";
import Natrag from "../../Components/Natrag/Natrag";
import { useState } from "react";

export default function Cookie() {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Natrag />
      <div className={styles.content}>
        <h1 className={styles.h1}>Upravljanje kolačićima</h1>
        <p className={styles.p}>
          Stranica www.poliklinika-ercegovic.hr koristi kolačiće (cookies) kako
          bismo pružili bolje korisničko iskustvo. Postavke kolačića možete
          kontrolirati i prilagođavati u vašem web pregledniku. Predlažemo
          prihvaćanje preporučenih postavki, a dodatne informacije o korištenju
          kolačića možete pronaći ispod u tekstu.
        </p>
        <h3 className={styles.h3}>Obavezni kolačići</h3>
        <p className={styles.p}>
          Kolačići koje koristimo kako bi vaše korištenje
          www.poliklinika-ercegovic.hr web stranice bilo brzo, ugodno i sigurno.
          Ovi kolačići su neophodni za funkcioniranje stranice i ne mogu biti
          isključeni.
        </p>
        <h3 className={styles.h3}>Neobavezni kolačići</h3>
        <ul className={styles.list}>
          <li className={styles.li}>
            <div className={styles.check}>
              <input
                type="checkbox"
                name="oglašavanje"
                value=""
                onChange={() => setCheck1(!check1)}
                checked={check1}
                className={styles.input}
              />
              <h4 className={styles.h4}>Kolačići za oglašavanje</h4>
            </div>
            <p className={styles.pList}>
              Ovi kolačići se koriste kako bismo prikazali vama relevantne
              oglase. Služe sprečavanju ponovnog pojavljivanja istog oglasa i
              osiguravaju ispravno isporučivanje oglasa na temelju vaših
              preferencija.
            </p>
          </li>
          <li className={styles.li}>
            <div className={styles.check}>
              <input
                type="checkbox"
                name="analitika"
                value=""
                onChange={() => setCheck2(!check2)}
                checked={check2}
                className={styles.input}
              />
              <h4 className={styles.h4}>Kolačići za anonimiziranu analitiku</h4>
            </div>
            <p className={styles.pList}>
              Ovi kolačići omogućuju nam praćenje broja posjeta i izvore prometa
              kako bismo mogli izmjeriti i poboljšati izvedbu naše stranice. Oni
              nam pomažu saznati koje su stranice popularne, a koje manje
              posjećene. Pomažu nam razumjeti kako posjetitelji koriste stranice
              te koliko su naše marketinške kampanje učinkovite. Sve informacije
              koje ovi kolačići prikupljaju su agregirane i anonimne.
            </p>
          </li>
        </ul>
        <button
          className={styles.btn}
          onClick={() => {
            setCheck1(false);
            setCheck2(false);
          }}
        >
          SPREMI POSTAVKE
        </button>
      </div>
    </div>
  );
}
