// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FindUsGrid_wrapper__ToGO7 {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 50px;
}

.FindUsGrid_imgwrapper__IYPiJ {
  width: 100%;
  display: flex;
  gap: 15px;
  width: calc(100% - 15px);
  padding-right: 15px;
  max-height: 210px;
}
.FindUsGrid_img__kXjNf {
  object-fit: cover;
  object-position: bottom;
  border-radius: 8px 8px 0 0;
}

.FindUsGrid_map__mCQCC {
  width: 100%;
  border: 0px;
  border-radius: 8px;
  height: 350px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .FindUsGrid_wrapper__ToGO7 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .FindUsGrid_map__mCQCC {
    height: 250px;
  }
}

@media screen and (max-width: 800px) {
  .FindUsGrid_map__mCQCC {
    height: 200px;
  }
}

@media screen and (max-width: 640px) {
  .FindUsGrid_wrapper__ToGO7 {
    padding-left: 15px;
  }
}

@media screen and (max-width: 450px) {
  .FindUsGrid_map__mCQCC {
    height: 150px;
  }
  .FindUsGrid_wrapper__ToGO7 {
    width: 90%;
  }
}

@media screen and (max-width: 380px) {
  .FindUsGrid_wrapper__ToGO7 {
    width: 88%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/FindUs/FindUsCards/FindUsGrid.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,wBAAwB;EACxB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".wrapper {\n  width: 80%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  padding-left: 50px;\n}\n\n.imgwrapper {\n  width: 100%;\n  display: flex;\n  gap: 15px;\n  width: calc(100% - 15px);\n  padding-right: 15px;\n  max-height: 210px;\n}\n.img {\n  object-fit: cover;\n  object-position: bottom;\n  border-radius: 8px 8px 0 0;\n}\n\n.map {\n  width: 100%;\n  border: 0px;\n  border-radius: 8px;\n  height: 350px;\n  object-fit: cover;\n}\n\n@media screen and (max-width: 1200px) {\n  .wrapper {\n    padding-left: 10px;\n  }\n}\n\n@media screen and (max-width: 1100px) {\n  .map {\n    height: 250px;\n  }\n}\n\n@media screen and (max-width: 800px) {\n  .map {\n    height: 200px;\n  }\n}\n\n@media screen and (max-width: 640px) {\n  .wrapper {\n    padding-left: 15px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .map {\n    height: 150px;\n  }\n  .wrapper {\n    width: 90%;\n  }\n}\n\n@media screen and (max-width: 380px) {\n  .wrapper {\n    width: 88%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FindUsGrid_wrapper__ToGO7`,
	"imgwrapper": `FindUsGrid_imgwrapper__IYPiJ`,
	"img": `FindUsGrid_img__kXjNf`,
	"map": `FindUsGrid_map__mCQCC`
};
export default ___CSS_LOADER_EXPORT___;
