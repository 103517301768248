import React, { useState, useEffect } from "react";
import styles from "./News.module.css";
import { NewsData } from "./NewsData";
import NewsCard from "./NewsCard/NewsCard";
import dot1 from "../../Assets/dot1.png";
import dot2 from "../../Assets/dot2.png";
import percentage from "../../Assets/percentage.svg";

export function News() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sale, setSale] = useState(true);
  const [news, setNews] = useState(window.innerWidth > 1400 && true);
  const [startX, setStartX] = useState<number | null>(null);
  const [endX, setEndX] = useState<number | null>(null);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    setEndX(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX && endX) {
      const difference = endX - startX;
      if (Math.abs(difference) > 50) {
        setNews(!news);
        setSale(!sale);
      }
    }
    setStartX(null);
    setEndX(null);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth < 1200) {
      if (sale) {
        setNews(false);
      } else {
        setSale(false);
        setNews(true);
      }
    } else {
      setNews(true);
      setSale(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sale]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.h2}>AKCIJE I NOVOSTI</h2>
      <div className={styles.container}>
        <div
          className={sale ? styles.left : styles.hidden}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className={styles.perc}>
            <img src={percentage} alt="" className={styles.percImg} />
          </div>
          <div className={styles.lefttext}>
            <NewsCard
              title={NewsData[0].title}
              text={NewsData[0].text}
              link={NewsData[0].link}
            />
          </div>
        </div>
        <div
          className={news ? styles.right : styles.hidden}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className={styles.rightContent}>
            <NewsCard
              title={NewsData[1].title}
              text={NewsData[1].text}
              link={NewsData[1].link}
            />
          </div>
        </div>
      </div>
      <div className={styles.dots}>
        {sale ? (
          <>
            <img src={dot1} alt="" className={styles.dot1} />
            <img
              src={dot2}
              alt=""
              className={styles.dot2}
              onClick={() => {
                setNews(!news);
                setSale(!sale);
              }}
            />
          </>
        ) : (
          <>
            <img
              src={dot2}
              alt=""
              className={styles.dot2}
              onClick={() => {
                setNews(!news);
                setSale(!sale);
              }}
            />
            <img src={dot1} alt="" className={styles.dot1} />
          </>
        )}
      </div>
    </div>
  );
}

export default News;
