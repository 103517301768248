export type Data = {
  id: number;
  title: string;
  text: string[];
  link: string;
};

export const NewsData: Data[] = [
  {
    id: 0,
    title: "Popusti u srpnju",
    text: [
      "15% popusta na:",
      "- Bocouture 100 jedinica",
      "- NCTF 135 (paket od tri tretmana)",
      "- Pb serum (low, medium)",
    ],
    link: "/akcijeinovosti",
  },
  {
    id: 1,
    title: "Novo u ponudi",
    text: [
      "Pbserum sadrzi kombinaciju enzima lipaze, liaze i kolagenaze te s njim možemo izvrsno djelovati na problematiku lokaliziranih masnih naslaga, celulita i ožiljaka. Kao takav je idealan za tretman podbratka, trbuha te lica. Ukoliko želite znati jeste li baš vi kandidat za navedene tretmane, obratite nam se s povjerenjem!",
    ],
    link: "/akcijeinovosti",
  },
];
