import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import styles from "./Form.module.css";
import {
  Interna,
  Rad,
  Aero,
  Oftamologija,
  Estetska,
  Dermatologija,
} from "./FormData";

const serviceID = "service_2tfbfcd";
const templateID = "template_e26s5db";
const userID = "US7pqAZv8xW8YNfS-";

export default function Form() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [napomena, setNapomena] = useState("");
  const [selectedOdjel, setSelectedOdjel] = useState("");
  const [selectedUsluga, setSelectedUsluga] = useState("");
  const Ostalo = ["Ostalo"];

  interface FormData {
    from_name: string;
    user_name: string;
    user_email: string;
    user_number: string;
    user_odjel: string;
    user_usluga: string;
    user_napomena: string;
  }

  const usluge: Record<string, string[]> = {
    InternaMedicina: Interna,
    MedicinaRadaISporta: Rad,
    AeromedicalCentar: Aero,
    Oftamologija: Oftamologija,
    EstetskaMedicina: Estetska,
    DermatologijaIVenerologija: Dermatologija,
    Ostalo,
  };

  const navigate = useNavigate();

  const handleOdjelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOdjel(selectedValue);
  };

  const handleUslugeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedUsluga(selectedValue);
  };

  const handleSubmit = (formData: FormData) => {
    const data: Record<string, string> = {
      ...formData,
    };

    emailjs
      .send(serviceID, templateID, data, userID)
      .then((response) => {
        console.log("Email sent:", response);
        navigate("/form/success");
      })
      .catch((error) => {
        console.error("Email error:", error);
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const value = event.target.value;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "number":
        setNumber(value);
        break;
      case "napomena":
        setNapomena(value);
        break;
      default:
        break;
    }
  };

  const formData = {
    from_name: name,
    user_name: name,
    user_email: email,
    user_number: number,
    user_odjel: selectedOdjel,
    user_usluga: selectedUsluga,
    user_napomena: napomena,
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.h1}>Dogovori svoj termin</h1>
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(formData);
          }}
        >
          <div className={styles.formDiv}>
            <div className={styles.left}>
              <label htmlFor="name" className={styles.label}>
                Ime i prezime
              </label>
              <input
                type="text"
                name="name"
                placeholder="Upiši"
                className={styles.input}
                onChange={(e) => handleChange(e, "name")}
              />
              <label htmlFor="email" className={styles.label}>
                Tvoja email adresa
              </label>
              <input
                type="text"
                name="email"
                placeholder="Upiši"
                className={styles.input}
                onChange={(e) => handleChange(e, "email")}
              />
              <label htmlFor="number" className={styles.label}>
                Kontakt broj
              </label>
              <input
                type="text"
                name="number"
                placeholder="npr. +385987654334"
                className={styles.input}
                onChange={(e) => handleChange(e, "number")}
              />
            </div>
            <div className={styles.right}>
              <label htmlFor="odjel" className={styles.label}>
                Odjel
              </label>
              <select
                name="odjel"
                id="odjel"
                className={styles.input}
                placeholder="Odaberi"
                value={selectedOdjel}
                onChange={handleOdjelChange}
              >
                <option value="">Odaberi</option>
                <option value="InternaMedicina">Interna medicina</option>
                <option value="MedicinaRadaISporta">
                  Medicina rada i sporta
                </option>
                <option value="AeromedicalCentar">Aeromedical centar</option>
                <option value="Oftamologija">Oftalmologija</option>
                <option value="EstetskaMedicina">Estetska medicina</option>
                <option value="DermatologijaIVenerologija">
                  Dermatologija i venerologija
                </option>
                <option value="Ostalo">Ostalo</option>
              </select>
              <label htmlFor="usluga" className={styles.label}>
                Vrsta usluge
              </label>
              <select
                name="usluga"
                id="usluga"
                className={styles.input}
                onChange={handleUslugeChange}
                placeholder="Odaberi"
              >
                {usluge[selectedOdjel] ? (
                  usluge[selectedOdjel].map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))
                ) : (
                  <option value="">Odaberi</option>
                )}
              </select>
              <label htmlFor="napomena" className={styles.label}>
                Tvoje napomene
              </label>
              <textarea
                placeholder="Upiši"
                name="napomena"
                className={styles.textarea}
                onChange={(e) => handleChange(e, "napomena")}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <button className={styles.button1} type="submit">
              <span className={styles.btnText1}>POŠALJI ZAHTJEV</span>
            </button>
            <button className={styles.button2} onClick={() => navigate("/")}>
              <span className={styles.btnText2}>ODUSTANI</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
