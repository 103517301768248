export interface IMedicina {
  image: string;
  title: string;
}

export const CardData: IMedicina[] = [
  {
    image: "/images/img1.png",
    title: "Pregledi vozača amatera i profesionalaca",
  },
  {
    image: "/images/img2.png",
    title: "Pregledi pomoraca",
  },
  {
    image: "/images/img3.png",
    title: "Pregled radnika",
  },
  {
    image: "/images/img4.png",
    title: "Pregled sudskih vještaka",
  },
  {
    image: "/images/img5.png",
    title: "Pregled za držanje i nošenje oružja ",
  },
  {
    image: "images/img6.png",
    title: "Pregled zaštitara i čuvara",
  },
  {
    image: "images/img7.png",
    title: "Pregled vatogasaca",
  },
  {
    image: "images/img8.png",
    title: "Pregled za školovanje i rad u zoni ionizirajućeg zračenja",
  },
  {
    image: "images/img9.png",
    title: "Pregled za upis na škole i fakultete",
  },
  {
    image: "images/img10.png",
    title: "Profesionalna orijentacija i selekcija pri upisu u škole",
  },
  {
    image: "images/img11.png",
    title: "Pregledi sportaša",
  },
  {
    image: "images/img12.png",
    title: "Edukacija radnika",
  },
  {
    image: "images/img13.png",
    title: "Edukacija prve pomoći radnika na radu",
  },
  {
    image: "images/img14.png",
    title: "Psihološka testiranja",
  },
  {
    image: "images/img15.png",
    title: "Testiranje manualnih sposobnosti",
  },
];

export interface IMedRada {
  description?: string[];
  nataste: string[];
  documentation?: string[];
  edukacija?: boolean;
}

export const MedicinaRadaData: IMedRada[] = [
  {
    description: [
      "Za vozače amatere i profesionalce (A, B, C, D i E kategorije)",
      "Izvanredni nadzorni pregledi za vozače",
      "Pregled vozača za prijepis inozemne vozačke dozvole",
    ],
    nataste: [
      "Na preglede za A i B kategoriju nije potrebno biti na tašte.",
      `Na preglede za profesionalne kategorije C, D, E i B korištene u profesionalne svrhe te izvanredni nadzorni pregled potrebno je doći <strong>natašte</strong>.`,
    ],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "kod produljenja te pri izvanrednom nadzornom potrebna je vozačka dozvola",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "ukoliko se radi o izvanrednom pregledu vozača, dostaviti <strong>dopis nadležne Policijske postaje</strong> o razlozima upućivanja i kategoriji za koju je potreban pregled",
    ],
  },
  {
    description: [
      "Liječnički pregledi za pomorce, članove posade pomorskih brodova, brodica i jahti unutarnje i vanjske plovidbe",
      "Prva pomorska knjižica i produženje pomorske knjižice",
      "Pregledi za ukrcaj (strane zastave)",
      "Alcohol-drug test",
      "Chemical blood test",
      "Psihološko testiranje za pomorce",
    ],
    nataste: [
      "Za ove preglede potrebno je biti <strong>natašte</strong>, te ukoliko kompanija zahtijeva, radi se i dodatna labaratorijska i medicinska obrada te izdaje različita ukrcajna dokumentacija ovisno o kompaniji ili zastavi ukrcaja.",
      `<strong>Ordinacija za medicinu rada dr. Željka Ercegović, Šoltanska 20, Split,</strong> ovlaštena je za preglede pomoraca koji plove pod <strong>Norveškom zastavom</strong> te za <strong>Oil and gas UK (UKOA)</strong>. Za preglede u Ordinaciji za medicinu rada se možete naručiti pozivom na telefon <strong><a href="tel:+38521371945" style="text-decoration:none; color:#747474">(021 371 945)</a></strong>, mobitel <strong><a href="tel:+38598225593" style="text-decoration:none; color:#747474">(+385 98 22 55 93)</a></strong> ili slanjem email-a na <strong><a href="mailto:ordinacija-ercegovic@xnet.hr" style="text-decoration:none; color:#747474">ordinacija-ercegovic@xnet.hr</a></strong> .`,
    ],
    documentation: [
      "osobna iskaznica ili putovnica",
      "moreplovnica (ukoliko je imate)",
      "žuta knjižica (ukoliko je imate)",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju",
    ],
  },
  {
    description: [
      "Pregled radnika koji radi na radnim mjestima s posebnim uvjetima rada",
      "Pregled radnika za zapošljavanje - opća radna sposobnost",
      "Kontrolni pregled radnika",
      "Izvanredni pregled radnika",
      "Pregledi radnika za rad u inozemstvu",
      "Pregledi radnika koji rade na računalu",
      "Pregled radnika koji rade noću",
    ],
    nataste: [
      "Za ove preglede potrebno je biti <strong>natašte</strong>, osim za preglede radnika koji rade na računalu.",
    ],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "HZMO rješenje (ukoliko ga imate)",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "ukoliko radnika upućuje ugovorna firma, potrebna je uputnica na <strong>RA-1 obrascu</strong>, a za pregled radnika koji rade noću <strong>NR-1 uputnicu</strong>. Trošak pregleda snosi HZZO i primjenjuju se cijene za periodične preglede po važećem cjeniku HZZO-a!",
      "za preglede radnika koji rade na računalu, dostaviti i <strong>nalaz oftalmologa</strong> ukoliko ga posjedujete",
    ],
  },
  {
    nataste: ["Za ove preglede potrebno je biti <strong>natašte</strong>."],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju",
    ],
  },
  {
    description: ["prethodni (prvi) pregledi", "izvanredni pregledi"],
    nataste: ["Za ove preglede potrebno je biti <strong>natašte</strong>. "],
    documentation: [
      "osobna iskaznica",
      "naočale i/ili kontaktne leće (ukoliko ih koristite)",
      "nalaz i mišljenje izabranog doktora medicine na <strong>propisanom obrascu</strong> sukladno pravilniku NN 36/2020 (akutne, kronične i psihičke bolesti)",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "ukoliko se radi o izvanrednom pregledu, potrebno je dostaviti i <strong>Rješenje nadležnog tijela</strong> Policijske uprave",
    ],
  },
  {
    nataste: ["Za ove preglede potrebno je biti <strong>natašte</strong>. "],
    documentation: [
      "osobna iskaznica",
      "naočale i/ili kontaktne leće (ukoliko ih koristite)",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "zadnje uvjerenje o zdravstvenoj sposobnosti ukoliko ga posjedujete",
      "ukoliko radnika upućuje ugovorna firma, potrebna je uputnica na <strong>RA-1 obrascu</strong>, a trošak pregleda snosi HZZO i primjenjuju se cijene za periodične preglede po važećem cjeniku HZZO-a!",
    ],
  },
  {
    nataste: ["Za ove preglede potrebno je biti <strong>natašte</strong>."],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "zadnje uvjerenje o zdravstvenoj sposobnosti (ukoliko ga posjedujete)",
    ],
  },
  {
    nataste: ["Za ove preglede potrebno je biti <strong>natašte</strong>."],
    documentation: [
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik i <strong>nalaz oftalmologa -  biomikroskopski pregled očne leće</strong>",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "ukoliko se radi o periodičnom pregledu, potrebno je dostaviti i <strong>zadnji nalaz dozimetrije</strong>",
      "ukoliko radnika upućuje ugovorna firma, potrebna je uputnica na <strong>RA-1 obrascu</strong>, a trošak pregleda snosi HZZO i primjenjuju se cijene za periodične preglede po važećem cjeniku HZZO-a! ",
    ],
  },
  {
    description: [
      "Liječnički pregled za upis u srednje škole",
      "Liječnički pregled za upis na fakultete",
      "Pshilološko testiranje provodi se ovisno o zanimanju",
    ],
    nataste: ["Nije potrebno biti natašte."],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju",
      "ukoliko je osoba mlađa od 16 godina, potrebno je doći u pratnji jednog od roditelja",
      "kod pregleda za upis na <strong>Kineziološki fakultet</strong> na liječnički pregled obavezno donijeti KIF-ov obrazac!",
    ],
  },
  {
    nataste: ["Nije potrebno biti natašte."],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik",
      "ukoliko je osoba mlađa od 16 godina, potrebno je doći u pratnji jednog od roditelja",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
    ],
  },
  {
    nataste: [
      "Pregled je važan za utvrđivanje i otklanjanje rizičnih čimbenika ključnih za prevenciju nastanka bolesti i ozljeda u sportu te unapređenje zdravlja sportaša.",
      " Za pregled je potrebno doći <strong>natašte</strong>.",
    ],
    documentation: [
      "osobna iskaznica",
      "klupsku iskaznicu za ovjeru (ukoliko je sportaš posjeduje)",
      "naočale i/ili kontaktne leće (ukoliko ih koristite)",
      "izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
      "ukoliko je osoba mlađa od 16 godina, potrebno je doći u pratnji jednog od roditelja",
    ],
  },
  {
    nataste: [
      "U dogovoru s poslodavcem organiziramo tematske edukacije iz područja zaštite zdravlja na radu.",
      "Osposobljavanje se vrši u grupama i pojedinačno uz prethodni dogovor. Cijena po dogovoru ovisno o broju polaznika i trajanju.",
    ],
    edukacija: true,
  },
  {
    nataste: [
      "Osposobljavanje se vrši u grupama i pojedinačno uz prethodni dogovor za termin.",
    ],
    edukacija: true,
  },
  {
    nataste: [
      "Testiranje manualnih sposobnosti zdravstvenih zanimanja shodno Pravilniku o mjerilima za prijam specijalizanata NN83/2015.",
    ],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju",
    ],
  },
  {
    nataste: [
      "Psihološka testiranja i testiranja psihomotornih sposobnosti vrše se shodno važećim pravilnicima i zakonima.",
    ],
    documentation: [
      "osobna iskaznica",
      "naočale ili kontaktne leće (ukoliko ih koristite)",
      "prilikom prvog dolaska u ordinaciju izvadak iz liječničkog kartona o akutnim kroničnim i psihičkim bolestima koji izdaje izabrani liječnik ",
      "ukoliko bolujete od nekih bolesti ili stanja dostaviti medicinsku dokumentaciju ",
    ],
  },
];
