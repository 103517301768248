import styles from "./ServicesCard.module.css";
import Star from "../../Assets/LabelStar.png";
import "../../Styles/Fonts.css";
import { useNavigate } from "react-router-dom";

interface CardProps {
  title: string;
  label?: string;
  id: number;
}
function ServicesCard({ title, label, id }: CardProps) {
  const navigate = useNavigate();

  const handleClick = (br: number) => {
    switch (br) {
      case 0:
        navigate("/odjeli/1");
        break;
      case 1:
        navigate("/medicina-rada");
        break;
      case 2:
        navigate("/odjeli/2");
        break;
      case 3:
        navigate("/odjeli/3");
        break;
      case 4:
        navigate("/odjeli/4");
        break;
      default:
        console.log("This route don't exist");
    }
  };

  return (
    <div className={styles.cardWrapper} onClick={() => handleClick(id)}>
      {label && (
        <div className={styles.labelWrapper}>
          <img alt="" src={Star} />
          <div className={styles.label}>{label}</div>
        </div>
      )}
      <div className={styles.title}>{title}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="75"
        height="4"
        fill="none"
        className={styles.divider}
      >
        <path
          stroke="#090446"
          stroke-linecap="round"
          stroke-width="2.12"
          d="M2 2h71.02"
        />
      </svg>
    </div>
  );
}

export default ServicesCard;
