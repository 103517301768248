import styles from "./AkcijeINovosti.module.css";
import { Link } from "react-router-dom";

interface AkcijeProps {
  date: string;
  title: string;
  image: string;
  link: string;
  width: string;
}

export default function AkcijeINovostiCard({
  date,
  title,
  image,
  link,
  width,
}: AkcijeProps) {
  return (
    <div className={styles.wrapper}>
      <img src={image} alt="" className={styles.img} width={width} />
      <div className={styles.text}>
        <p className={styles.date}>{date}</p>
        <h2 className={styles.h2}>{title}</h2>
        <Link to={link} className={styles.link}>
          <p className={styles.p}>Pročitaj više</p>
        </Link>
      </div>
    </div>
  );
}
