import React from "react";
import styles from "./MakeAnAppointment.module.css";
import StepsImage from "../../Assets/AppointmentSteps.png";
import { useNavigate } from "react-router-dom";

const MakeAnAppointment: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.centeredContainer}>
      <div className={styles.container}>
        <div className={styles.columnLeft}>
          <div className={styles.subcolumnLeft}>
            <img
              src={StepsImage}
              alt="First Subcolumn"
              className={styles.AppointmentImage}
            />
          </div>
          <div className={styles.subcolumnRight}>
            <div className={styles.row1}>
              <h3 className={styles.heading}>
                <span className={styles.number}>1.</span> Kontaktiraj nas
              </h3>
              <p>
                Ispuni formu i pošalji svoj upit ili nazovi na naš kontakt broj.
              </p>
            </div>
            <div className={styles.row2}>
              <h3 className={styles.heading}>
                <span className={styles.number}>2.</span> Potvrda termina
              </h3>
              <p>
                Kontaktirat ćemo Vas putem mail-a ili kontakt broja o terminu
                Vašeg dolaska.
              </p>
            </div>
            <div className={styles.row3}>
              <h3 className={styles.heading}>
                <span className={styles.number}>3.</span> Tvoj liječnik te
                očekuje
              </h3>
              <p>
                Još preostaje samo doći na zakazani termin i prepustiti se u
                ruke naših stručnjaka.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.columnRight}>
          <p className={styles.Heading}>DOGOVORI PREGLED</p>
          <p className={styles.Subheading}>U samo par klikova do termina</p>
          <p className={styles.Paragraph}>
            U samo nekoliko jednostavnih koraka, možete rezervirati svoj termin
            u našoj poliklinici i osigurati brzu i kvalitetnu uslugu.{" "}
          </p>
          <button className={styles.btn} onClick={() => navigate("/form")}>
            <span className={styles.btnText}>DOGOVORI TERMIN</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakeAnAppointment;
