import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/HomePage/HomePage";
import MedicinaRada from "./Pages/MedicinaRadaPage/MedicinaRada";
import { Odjeli } from "./Pages/Odjeli/Odjeli";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import AkcijePage from "./Pages/AkcijeINovostiPage/AkcijeINovostiPage";
import Akcije from "./Pages/Akcije/Akcije";
import Tim from "./Pages/Tim/Tim";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Cookie from "./Pages/Cookie/Cookie";
import FormPage from "./Pages/FormPage/FormPage";
interface LayoutProps {
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      {children}
      <Footer />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <HomePage />
      </Layout>
    ),
  },
  {
    path: `/medicina-rada/`,
    element: (
      <Layout>
        <MedicinaRada />
      </Layout>
    ),
  },
  {
    path: `/medicina-rada/:id`,
    element: (
      <Layout>
        <MedicinaRada />
      </Layout>
    ),
  },
  {
    path: "/odjeli/:id",
    element: (
      <Layout>
        <Odjeli />
      </Layout>
    ),
  },
  {
    path: "/akcijeinovosti",
    element: (
      <Layout>
        <AkcijePage />
      </Layout>
    ),
  },
  {
    path: "/akcije/:id",
    element: (
      <Layout>
        <Akcije akcija={true} />
      </Layout>
    ),
  },
  {
    path: "/novosti/:id",
    element: (
      <Layout>
        <Akcije akcija={false} />
      </Layout>
    ),
  },
  {
    path: "/tim",
    element: (
      <Layout>
        <Tim />
      </Layout>
    ),
  },
  {
    path: "/cookie",
    element: (
      <Layout>
        <Cookie />
      </Layout>
    ),
  },
  {
    path: "/form",
    element: (
      <Layout>
        <FormPage id={0} />
      </Layout>
    ),
  },
  {
    path: "/form/success",
    element: (
      <Layout>
        <FormPage id={1} />
      </Layout>
    ),
  },
  {
    path: "/form/fail",
    element: (
      <Layout>
        <FormPage id={2} />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
