// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PartnerOrganizations_partner-organizations__nxrmA {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.PartnerOrganizations_header-row__tYZFM {
  display: flex;
  justify-content: flex-start;
}

.PartnerOrganizations_logo-row__a34lv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.PartnerOrganizations_heading__gxsQ8 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #747474;
  padding-left: 4%;
}

.PartnerOrganizations_wiener__Sf2L5 {
  height: 80px;
  width: auto;
  filter: blur(0.5px);
}

@media screen and (max-width: 1200px) {
  .PartnerOrganizations_logo-row__a34lv {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }

  .PartnerOrganizations_header-row__tYZFM {
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/PartnerOrganizations/PartnerOrganizations.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".partner-organizations {\n  display: flex;\n  flex-direction: column;\n  gap: 50px;\n}\n\n.header-row {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.logo-row {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.heading {\n  font-family: \"Lato\";\n  font-size: 20px;\n  font-weight: 800;\n  line-height: 24px;\n  text-align: left;\n  color: #747474;\n  padding-left: 4%;\n}\n\n.wiener {\n  height: 80px;\n  width: auto;\n  filter: blur(0.5px);\n}\n\n@media screen and (max-width: 1200px) {\n  .logo-row {\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 80px;\n  }\n\n  .header-row {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partner-organizations": `PartnerOrganizations_partner-organizations__nxrmA`,
	"header-row": `PartnerOrganizations_header-row__tYZFM`,
	"logo-row": `PartnerOrganizations_logo-row__a34lv`,
	"heading": `PartnerOrganizations_heading__gxsQ8`,
	"wiener": `PartnerOrganizations_wiener__Sf2L5`
};
export default ___CSS_LOADER_EXPORT___;
