// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FindUsText_textWrapper__hCKUC {
  width: 100%;
}

.FindUsText_h4__NSUog {
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  margin-block-end: 0px;
}

.FindUsText_p__IVRMq {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans";
  color: #747474;
  padding-left: 35px;
  margin-block-start: 0px;
}

.FindUsText_list__jOl6O {
  list-style: none;
  padding-left: 0;
  margin-block-start: 10px;
}

.FindUsText_icon__w9gSh {
  padding-right: 10px;
}
.FindUsText_sec__HGdQS {
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans";
  padding-left: 35px;
}

@media screen and (max-width: 420px) {
  .FindUsText_p__IVRMq {
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/FindUs/FindUsCards/FindUsText.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,cAAc;EACd,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".textWrapper {\n  width: 100%;\n}\n\n.h4 {\n  font-size: 16px;\n  font-weight: 700;\n  font-family: \"Open Sans\";\n  display: flex;\n  align-items: center;\n  margin-block-end: 0px;\n}\n\n.p {\n  font-size: 16px;\n  font-weight: 400;\n  font-family: \"Open Sans\";\n  color: #747474;\n  padding-left: 35px;\n  margin-block-start: 0px;\n}\n\n.list {\n  list-style: none;\n  padding-left: 0;\n  margin-block-start: 10px;\n}\n\n.icon {\n  padding-right: 10px;\n}\n.sec {\n  font-size: 16px;\n  font-weight: 700;\n  font-family: \"Open Sans\";\n  padding-left: 35px;\n}\n\n@media screen and (max-width: 420px) {\n  .p {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textWrapper": `FindUsText_textWrapper__hCKUC`,
	"h4": `FindUsText_h4__NSUog`,
	"p": `FindUsText_p__IVRMq`,
	"list": `FindUsText_list__jOl6O`,
	"icon": `FindUsText_icon__w9gSh`,
	"sec": `FindUsText_sec__HGdQS`
};
export default ___CSS_LOADER_EXPORT___;
