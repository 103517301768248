// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormPage_wrapper__lnHlA {
  padding-top: 60px;
}
.FormPage_back__qKlMn {
  padding-left: 150px;
  padding-bottom: 50px;
}

.FormPage_formContainer__yQUJZ {
  padding-bottom: 40px;
  padding-left: 30px;
}

@media screen and (max-width: 1000px) {
  .FormPage_formContainer__yQUJZ {
    padding-left: 0;
  }
  .FormPage_back__qKlMn {
    padding-left: 70px;
  }
}

@media screen and (max-width: 800px) {
  .FormPage_formContainer__yQUJZ {
    padding-right: 30px;
  }
}

@media screen and (max-width: 600px) {
  .FormPage_back__qKlMn {
    padding-left: 50px;
  }
}

@media screen and (max-width: 450px) {
  .FormPage_back__qKlMn {
    padding-left: 30px;
  }
}

@media screen and (max-width: 376px) {
  .FormPage_back__qKlMn {
    padding-left: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/FormPage/FormPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  padding-top: 60px;\n}\n.back {\n  padding-left: 150px;\n  padding-bottom: 50px;\n}\n\n.formContainer {\n  padding-bottom: 40px;\n  padding-left: 30px;\n}\n\n@media screen and (max-width: 1000px) {\n  .formContainer {\n    padding-left: 0;\n  }\n  .back {\n    padding-left: 70px;\n  }\n}\n\n@media screen and (max-width: 800px) {\n  .formContainer {\n    padding-right: 30px;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .back {\n    padding-left: 50px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .back {\n    padding-left: 30px;\n  }\n}\n\n@media screen and (max-width: 376px) {\n  .back {\n    padding-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FormPage_wrapper__lnHlA`,
	"back": `FormPage_back__qKlMn`,
	"formContainer": `FormPage_formContainer__yQUJZ`
};
export default ___CSS_LOADER_EXPORT___;
