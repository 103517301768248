import styles from "./HomePage.module.css";
import NasiOdjeli from "./components/NasiOdjeli/NasiOdjeli";
import MakeAnAppointment from "../../Components/MakeAnAppointment/MakeAnAppointment";
import PartnerOrganizations from "../../Components/PartnerOrganizations/PartnerOrganizations";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Header from "../../Components/Header/Header";
import News from "../../Components/News/News";
import FindUs from "../../Components/FindUs/FindUs";
import img1 from "../../Assets/FindUsImgs/FUImg1.png";
import img2 from "../../Assets/FindUsImgs/FUImg2.png";
import img3 from "../../Assets/FindUsImgs/FUImg3.png";
import img4 from "../../Assets/FindUsImgs/FUImg4.png";

function HomePage() {
  const width1 = ["66%", "34%"];
  const width2 = ["47%", "53%"];
  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div id="home" className={styles.odjeli}>
          <Header />
        </div>
        <News />
        <div id="departments" className={styles.odjeli}>
          <NasiOdjeli />
        </div>
        <MakeAnAppointment />
        <div id="about" className={styles.onama}>
          <AboutUs />
        </div>
        <PartnerOrganizations />
        <div id="contact" className={styles.kontakt}>
          <FindUs
            id={0}
            info={true}
            img={[
              { width: width1, url: img1 },
              { width: width1, url: img2 },
            ]}
          />
        </div>
        <FindUs
          id={1}
          info={false}
          img={[
            { width: width2, url: img3 },
            { width: width2, url: img4 },
          ]}
        />
      </div>
    </div>
  );
}

export default HomePage;
