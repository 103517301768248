import styles from "./MedicinaRada.module.css";
import MedicinaRadaContainer from "../../Components/MedicinaRada/MedicinaRadaContainer";
import MedRada from "../../Components/MedRadaPages/MedRadaPages";
import { useParams } from "react-router-dom";
import Natrag from "../../Components/Natrag/Natrag";

function MedicinaRada() {
  let numId: number | null = 0;
  const { id } = useParams();
  if (id !== undefined) {
    numId = parseInt(id!, 10);
  } else {
    numId = null;
  }
  return (
    <>
      <div className={styles.wrapper}>
        <Natrag />
        <div className={styles.text}>
          <h1 className={styles.h1}>Medicina rada i sporta</h1>
          <p className={styles.p}>
            Usluge medicine rada i sporta se obavljaju na dvije lokacije - u
            Poliklinici (Lička ul. 2) i u Ordinaciji za medicinu rada dr.
            Ercegović (Šoltanska 20).
          </p>
          <p className={styles.p}>
            Za sve ponuđene liječničke preglede, potrebnu dokumentaciju možeš
            vidjeti ulaskom na pojedini pregled.
          </p>
        </div>
        {numId === null ? <MedicinaRadaContainer /> : <MedRada id={numId} />}
      </div>
    </>
  );
}

export default MedicinaRada;
