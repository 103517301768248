import React from "react";
import styles from "./MedRadaPages.module.css";
import { CardData, MedicinaRadaData } from "../MedicinaRada/MedicinaRadaData";
import backImg from "../../Assets/backImg.png";
import { useNavigate } from "react-router-dom";
import phone from "../../Assets/phone1.png";
import calendar from "../../Assets/calendar.png";

interface MedRadaProps {
  id: number;
}

export default function MedRadaPages({ id }: MedRadaProps) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/medicina-rada");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.start}>
          <img src={`/images/img${id}.png`} alt="" className={styles.image} />
          <h1 className={styles.h1}>{CardData[id - 1].title}</h1>
        </div>
        <div className={styles.content}>
          <ul className={styles.list}>
            {MedicinaRadaData[id - 1].description?.map((item, index) => (
              <li key={index}>
                <p className={styles.p}>{item}</p>
              </li>
            ))}
          </ul>
          {MedicinaRadaData[id - 1].nataste.map((item, index) => (
            <p
              key={index}
              className={styles.p}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
          {!MedicinaRadaData[id - 1].edukacija && (
            <h3 className={styles.h3}>Potrebna dokumentacija</h3>
          )}
          <ul className={styles.list}>
            {MedicinaRadaData[id - 1].documentation?.map((item, index) => (
              <li key={index}>
                <p
                  className={styles.p}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </li>
            ))}
          </ul>
          <button className={styles.btn} onClick={handleButtonClick}>
            <img src={backImg} alt="" className={styles.btnback} />
            <span className={styles.natrag}>Natrag na listu pregleda</span>
          </button>
        </div>
      </div>
      <div className={styles.right}>
        <button className={styles.btn1} onClick={() => navigate("/form")}>
          <img src={calendar} alt="" className={styles.btnimg1} />
          <span className={styles.btntext}>DOGOVORI TERMIN</span>
        </button>
        <button className={styles.btn2}>
          <img src={phone} alt="" className={styles.btnimg2} />
          <span className={styles.btntext}>NAZOVI ODMAH</span>
        </button>
      </div>
    </div>
  );
}
