import { useNavigate } from "react-router-dom";
import styles from "./Natrag.module.css";
import backImg from "../../Assets/backImg.png";

export default function Natrag() {
  const navigate = useNavigate();

  return (
    <div className={styles.back} onClick={() => navigate("/")}>
      <img src={backImg} alt="" className={styles.backimg} />
      <p className={styles.backtext}>Natrag na Početnu</p>
    </div>
  );
}
