interface Data {
  url: string;
  text: string;
}

export const HeaderData: Data[] = [
  {
    url: "home",
    text: "POČETNA",
  },
  {
    url: "departments",
    text: "DJELATNOSTI I USLUGE",
  },
  {
    url: "about",
    text: "O POLIKLINICI",
  },
  {
    url: "contact",
    text: "KONTAKT",
  },
];
