import map1 from "../../Assets/FindUsImgs/map1.png";
import map2 from "../../Assets/FindUsImgs/map2.png";

export type Data = {
  title: string;
  address: string;
  heading1: string;
  info1: string[];
  heading2?: string;
  info2?: string[];
  email: string;
};

export const FindUsData: Data[] = [
  {
    title: "Kako do Poliklinike Ercegović",
    address: "Lička ul. 2, 21000, Split",
    heading1: "Poliklinika",
    info1: [
      "Pon - pet",
      "od 8 do 16h",
      'Tel. <a href="tel:021484722" style="text-decoration:none; color:#747474">021/48 47 22</a>',
      'Mob. <a href="tel:+38598225593" style="text-decoration:none; color:#747474">(+385) 98 22 55 93</a>',
      'Fax : <a href="tel:+021485472" style="text-decoration:none; color:#747474"> 021/485 472</a>',
    ],
    heading2: "Dermatologija i estetska medicina",
    info2: [
      "Termini po dogovoru",
      'Mob. <a href="tel:+385993414555" style="text-decoration:none; color:#747474">(+385) 99 341 4555</a>',
    ],
    email:
      '<a href="mailto:poliklinika.ercegovic1@gmail.com" style="text-decoration:none; color:#747474">poliklinika.ercegovic1@gmail.com</a>',
  },
  {
    title: "Kako do Ordinacije za medicinu rada dr. Željka Ercegović",
    address: "Šoltanska, 20, Split",
    heading1: "Ordinacija",
    info1: [
      "Pon - pet",
      "od 8 do 16h",
      'Tel. <a href="tel:+38521371945" style="text-decoration:none; color:#747474">021/371 945</a>',
      'Mob. <a href="tel:+38598225593" style="text-decoration:none; color:#747474">(+385) 98 22 55 93</a>',
      'Fax : <a href="tel:+021485472" style="text-decoration:none; color:#747474"> 021/485 472</a>',
    ],
    email:
      '<a href="mailto:ordinacija-ercegovic@xnet.hr" style="text-decoration:none; color:#747474">ordinacija-ercegovic@xnet.hr</a>',
  },
];

type Maps = {
  url: string;
};

export const FindUsMaps: Maps[] = [
  {
    // url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2893.348041451495!2d16.4415497122159!3d43.51593447098846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355e08f076bd41%3A0x8801b42dcb811f98!2sLi%C4%8Dka%20ul.%202%2C%2021000%2C%20Split!5e0!3m2!1sen!2shr!4v1702555423485!5m2!1sen!2shr",
    url: map1,
  },
  {
    // url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2893.8419687150213!2d16.459599612215364!3d43.50563217098898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355e1f17a8eb8b%3A0x132a4a401d6e1973!2s%C5%A0oltanska%20ul.%2020%2C%2021000%2C%20Split!5e0!3m2!1sen!2shr!4v1702555475734!5m2!1sen!2shr",
    url: map2,
  },
];
