import { useState, useEffect } from "react";
import styles from "./Header.module.css";
import Logo from "../../Assets/Logo.png";
import "../../Styles/Fonts.css";
import { HeaderData } from "./HeaderData";
import menu from "../../Assets/menu.svg";
import x from "../../Assets/x.png";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";

function Header() {
  const [activeLink, setActiveLink] = useState("/home");
  const [mobileNavVisible, setMobileNavVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (mobileNavVisible) {
        setMobileNavVisible(false);
      }
    });
  }, [mobileNavVisible]);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    const numLink = parseInt(link!, 10);

    scroll.scrollTo(numLink, {
      duration: 800,
      smooth: "easeInOutQuad",
    });
  };

  const navigate = useNavigate();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.logoWrapper}>
        <img alt="" src={Logo} className={styles.logo} />
      </div>
      <div
        className={mobileNavVisible ? styles.mobileNav : styles.pagesWrapper}
      >
        {HeaderData.map((item, index) => (
          <ScrollLink
            to={item.url}
            spy={true}
            smooth={true}
            offset={-70}
            duration={800}
            className={
              mobileNavVisible
                ? `labelMediumSemiBold ${styles.headerTextButton} ${styles.mobileLinks}`
                : `labelMediumSemiBold ${styles.headerTextButton} ${
                    activeLink === `/${item.url}` ? styles.activeLink : ""
                  }`
            }
            onClick={() => {
              setMobileNavVisible(false);
              handleLinkClick(`/${item.url}`);
            }}
            key={index}
          >
            {item.text}
          </ScrollLink>
        ))}
        <a
          href={"/akcijeinovosti"}
          className={
            mobileNavVisible
              ? ` labelMediumSemiBold ${styles.headerTextButton} ${
                  activeLink === `/news` ? styles.activeLink : ""
                } ${styles.mobileLinks}`
              : `${styles.headerTextButton} ${
                  activeLink === "/news" ? styles.activeLink : ""
                }`
          }
          onClick={() => handleLinkClick("/akcijeinovosti")}
        >
          AKCIJE I NOVOSTI
        </a>
        <div
          className={
            mobileNavVisible ? styles.mobileBtnWrapper : styles.btnWrapper
          }
        >
          <button
            className={mobileNavVisible ? styles.mobileBtn : styles.btn}
            onClick={() => navigate("/form")}
          >
            DOGOVORI TERMIN
          </button>
        </div>
      </div>
      <div
        className={styles.menu}
        onClick={() => setMobileNavVisible(!mobileNavVisible)}
      >
        {mobileNavVisible ? (
          <img className={styles.menu} src={x} alt="" />
        ) : (
          <img className={styles.menu} src={menu} alt="" />
        )}
      </div>
    </div>
  );
}

export default Header;
