import React from "react";
import styles from "./MedicinaRadaContainer.module.css";
import MedicinaRadaCard from "./MedicinaRadaCard/MedicinaRadaCard";
import { CardData } from "./MedicinaRadaData";
import { Link } from "react-router-dom";

export default function MedicinaRadaContainer() {
  return (
    <div className={styles.wrapper}>
      {CardData.map((card, index) => (
        <Link
          to={`/medicina-rada/${index + 1}`}
          className={styles.link}
          key={index}
        >
          <MedicinaRadaCard key={index} image={card.image} title={card.title} />
        </Link>
      ))}
    </div>
  );
}
