interface Data {
  title: string;
  description: string;
  list?: string[];
  doc?: string[];
  spec?: string[];
  drops?: string[];
  dropText?: string[];

  relatedTitle?: string;
  relatedDescription?: string;
  relatedList?: string[];
  relatedDoc?: string[];
  relatedSpec?: string[];

  docAvion?: string[];
  plane?: boolean;
  novo?: boolean;
  btns?: boolean;

  newList?: string[];
}

export const OdjeliData: Data[] = [
  {
    title: "Interna medicina",
    description: `Pregledi se realiziraju uputnicama privatnih osiguranja (npr. Allianz, Merkur, Grawe, Uniqua,
Croatia zdravstveno osiguranje) ili po cjeniku odobrenom od Liječničke komore za privatne
pacijente. Ukoliko do sada niste bili korisnica ili korisnik naših usluga, molimo da donesete
dokumentaciju o ranijem liječenju i dijagnostici.`,
    doc: ["GORAN RADOVNIKOVIĆ, "],
    spec: [
      "dr.med., specijalist opće interne medicine, subspecijalist endokrinologije i dijabetologije",
    ],
    drops: [
      "Sub/specijalistički pregledi uz navedene pretrage te konzultacije za odrasle",
      "Predoperativni pregledi odraslih",
      "Sistematski pregledi za odrasle",
      "EKG - elektrokardiogram",
      "KMAT - kontinuirano mjerenje arterijskog tlaka",
      "Ultrazvuk trbušnih organa ili abdomena",
      "Ultrazvuk štitnjače",
      "Ultrazvuk površinskih struktura",
      "Ultrazvuk slinovnica",
      "Ultrazvuk testisa",
    ],
    dropText: [
      `Specijalist interne medicine, koji se bavi dijagnosticiranjem i liječenjem bolesti unutarnjih organa
      započinje ovakav pregled razgovorom i upoznavanjem sa pacijentom da bi doznao više o
      prethodnim bolestima, obiteljskim bolestima i o dinamici sadašnjih tegoba, a potom slijedi
      detaljan klinički pregled pacijenta uz snimanje elektrokardiograma, te analizu laboratorijskih i
      izvođenje drugih npr. ultrazvučnih pretraga, i konačno daje zaključno mišljenje i savjet o
      daljnjem postupanju. Dodatni subspecijalistički aspekt internističkog pregleda ovdje je onaj
      endokrinološko-dijabetološki: okrenut je bolestima žlijezda poput hipofize, štitnjače, gušterače,
      nadbubrežnih žlijezda, spolnih žlijezda, zatim najčešćim metaboličkim bolestima današnjice:
      debljinom i dijabetesom. Samo te dvije bolesti - haraju svijetom u razmjerima epidemije.`,
      `Operateri različitih kirurških specijalnosti traže preoperativni internistički pregled sa
      interpretiranim EKG-om, u svrhu definiranja kardiorespiratorne spremnosti pacijenta za zahvat,
      uz obavezni anesteziološki pregled koji slijedi iza internističkog uz eventualne dodatne pretrage.`,
      `Sigurni smo da potpuno podrazumijevate vlastitu vožnju u tehnički provjerenom zrakoplovu,
      brodu, vlaku ili osobnom vozilu, pa čak i liftu. Shvaćate paralelu, naravno. Ovdje možete obaviti
      sistematske i preventivne preglede po programu osiguravajućih kuća te po vlastitom nahođenju,
      ili nakon dogovora sa internistom. Imate osiguran individualni pristup. Pregledi se sastoje od
      internističkog pregleda, dopunjenog sa EKG-om, ultrazvučnim i laboratorijskim pretragama.
      Zaključno dobijete upute o terapiji, daljnjim pretragama ukoliko su potrebne te Vama
      prilagođene savjete.
      Da bi pregled bio adekvatan, potrebno se pripremiti: dan prije pregleda konzumirajte manje
      količine hrane no inače, i to probavljivu, kuhanu hranu uz dosta tekućine (bistre juhe, čajevi).
      Bez gaziranih pića i alkohola! Ne uzimajte hranu koja izaziva nadutost (npr. grah, grašak, kelj,
      zelje, svježe voće i povrće, pečeno i suho meso, mlijeko i mliječne proizvode), a zadnji obrok
      pojedite prije 18 sati. Na dan pregleda je zbog laboratorijskih pretraga i ultrazvuka abdomena
      potrebno doći natašte. Lijekove koje inače pijete u jutarnjim satima, popijte sa vodom.`,
      `Osnovna je pretraga u internističkom pregledu, koja nam daje informaciju o stanju srca u
      stvarnom vremenu. Na jednostavan i bezbolan način snima električne impulse u ispis dok je na
      liječniku da interpretira nalaz u kontekstu datog pacijenta.`,
      `24-satno Kontinuirano Mjerenje Arterijskog Tlaka (Holter tlaka, KMAT) – je metoda mjerenja
      arterijskog tlaka malim prijenosnim uređajem i manžetom na nadlaktici tijekom 24 sata za
      vrijeme uobičajenih aktivnosti i spavanja, kod odraslih osoba, a u svrhu procjene težine do sada
      neliječene arterijske hipertenzije ili procjene učinkovitosti terapije za istu – koju treba uzeti na
      dan pretrage. Uređaj se postavlja u dogovoreno vrijeme i vraća na očitanje nakon 24 do 48 sati.
      Tijekom tog vremena vodite dnevnik aktivnosti. Za pretragu nije potrebna priprema.`,
      `Ovo je neinvazivna i bezbolna metoda kojom se sondom slikovno prikazuju organi trbuha i
      zdjelice (jetre, žučnog mjehura, slezene, gušterače, bubrega, mokraćnog mjehura i prostate), a
      omogućuje i procjenu veličine žučnih vodova i velikih krvnih žila u trbuhu. Važno je da ništa ne
      jedete šest sati prije pretrage, a dan prije izbjegavajte hranu koja nadima (svježe voće, povrće,
      gazirani napitci, mahunarke). Na pregled treba doći sa što punijim mokraćnim mjehurom, tako
      da sat vremena prije dolaska popijete najmanje pola litre vode. Ultrazvučna pretraga se može
      izvoditi zasebno po potrebi pacijenta ili kao dio sistematskih, preventivnih i sub/specijalističkih
      pregleda.`,
      `Za navedenu ultrazvučnu pretragu nije potrebna posebna priprema. Ultrazvuk štitnjače se može
      izvoditi zasebno po potrebi pacijenta ili kao dio sistematskih, preventivnih i sub/specijalističkih
      pregleda.`,
      `Za navedenu ultrazvučnu pretragu nije potrebna posebna priprema. Ultrazvučna pretraga se
      može izvoditi zasebno po potrebi pacijenta ili kao dio sistematskih, preventivnih i
      sub/specijalističkih pregleda.`,
      `Za navedenu ultrazvučnu pretragu nije potrebna posebna priprema. Ultrazvučna pretraga se
      može izvoditi zasebno po potrebi pacijenta ili kao dio sistematskih, preventivnih i
      sub/specijalističkih pregleda.`,
      `Za navedenu ultrazvučnu pretragu nije potrebna posebna priprema. Ultrazvučna pretraga se
      može izvoditi zasebno po potrebi pacijenta ili kao dio sistematskih, preventivnih i
      sub/specijalističkih pregleda.`,
    ],
  },
  {
    title: "Aeromedical Centar (HR.AeMC.02) - jedini u Dalmaciji",
    description:
      "Ovlašteni smo za inicijalne (prve) preglede, produljenje, obnavljanje i izvanredne preglede za sve kategorije zrakoplovnog osoblja u skladu sa EU (EASA) propisima.",
    list: [
      "Pregled za civilne, profesionalne i sportske pilote",
      "Pregled kontrolora leta",
      "Pregled za padobrance",
      "Pregled za pilote  parajedrilica i ovjesnih jedrilica",
      "Pregled za članove kabinske posade",
      "Procjena mentalnog zdravlja zrakoplovnog osoblja",
    ],
    relatedTitle:
      "Zrakoplovno-medicinski ispitivači (Autorised medical Examiners - AME)",
    relatedDescription:
      "Preglede zrakoplovnog osoblja u našem Aero-medical centru obavljaju certificirani liječnici:",
    docAvion: [
      "Julka Marković Posavec, dr.med.specijalist medicine rada (HR.AME.18)",
      "Željka Ercegović, dr.med. specijalist medicine rada (HR.AME.17)",
      "Ivana Tomasović dr.med. specijalist medicine rada i sporta (HR.AME.31)",
    ],
    plane: true,
    btns: false,
  },
  {
    title: "Oftalmologija",
    description: `<strong>Detaljno pregledajte Vaše oči bez duljeg čekanja, uz 
      modernu opremu i individualan pristup, pružamo 
      preglede za sve generacije.</strong><br>
      Na pregled se možete naručiti osobno ili preko 
      partnerskih privatnih osiguranja ( Allianz, Croatia, 
      Grawe, Merkur, Uniqa, Wiener).<br>
      Molimo da na pregled donesete naočale, kontaktne leće
      i raniju medicinsku dokumentaciju ako posjedujete.`,
    drops: [
      "KOMPLETAN OFTALMOLOŠKI PREGLED ODRASLIH",
      "OFTALMOLOŠKI PREGLED DJECE (4+)",
      "PREGLED ZA POLUTVRDE KONTAKTNE LEĆE",
      "PREGLED ZA MEKANE KONTAKTNE LEĆE",
      "PREVENTIVNI PREGLEDI ZA KORISNIKE RAČUNALA",
      "RANO OTKRIVANJE GLAUKOMA (glaukomski paket)",
      "TESTIRANJE KOLORNOG VIDA - ANOMALOSKOPIJA",
      "KOMPJUTERIZIRANO VIDNO POLJE",
      "OCT OČNOG ŽIVCA I MAKULE (uskoro)",
      "MALI ZAHVATI",
    ],
    dropText: [
      `<strong>Pregled oka nije samo pregled vida za naočale.</strong><br><br>
      <strong>Kompletan oftalmološki pregled uključuje:</strong> uvodni 
      razgovor, provjeru vida na daljinu i na blizu, određivanje
      dioptrije, pregled prednjeg segmenta oka, pregled 
      stražnjeg segmenta oka, mjerenje očnog tlaka, mišljenje
      oftalmologa te preporuku za terapiju i eventualne 
      dodatne pretrage i kontrole.<br><br>
      <strong>Kompletan oftalmološki pregled ne uključuje: </strong>
      retinološki pregled, skijaskopiju, pahimetriju, testiranje 
      vidnog polje, OCT i drugo.<br> 
      Pregled je potpuno bezbolan i traje oko 30-45 minuta. 
      Kad su potrebne dodatne pretrage i duže.<br>
      Preporuka je <strong>jednom godišnje</strong> obaviti kompletan 
      oftalmološki pregled.`,
      `Oftalmološki pregled djeteta izuzetno je važan jer se 
      većina oftalmoloških problema kod djece može 
      uspješno riješiti ako se rano otkriju. Vid je ključan za 
      pravilan razvoj djeteta, njegovu sposobnost učenja i 
      istraživanja te razvoj socijalnih vještina.<br><br>                         
      <strong>Djeca se često ne žale na vidne probleme jer ne 
      znaju da ono što vide nije normalno.</strong> Zato samo oftalmološki pregledi omogućuju rano otkrivanje 
      astigmatizma, kratkovidnosti, dalekovidnosti, strabizma
      i slabovidnosti u djece.<br><br>                                                    
      Redoviti oftalmološki pregledi savjetuju se od <strong>4. 
      godine života barem jednom godišnje</strong> s obzirom da
      djeca rastu te se i njihov vid može mijenjati. <br><br>                 
      Kad se uz pregled djeteta radi skijaskopija (objektivni 
      pregled dioptrije u širokoj zjenici) pregled može trajati 
      oko 1h i 30 minuta.`,
      `Ako se odlučite za nošenje kontaktnih leća specijalist 
      oftalmolog će vam nakon pregleda vida i pregleda oka 
      predložiti kontaktnu leću koja je optimalna za vas.<br><br>
      Koju kontaktnu leću odabrati mekanu ili polutvrdu ovisi 
      o građi oka, vašoj dioptriji, osobnim potrebama te 
      preporuci oftalmologa.<br><br>
      Nakon odabira optimalne kontaktne leće nudimo i 
      poduku o korištenju i održavanju vaših kontaktnih leća 
      te kasnije praćenje i redovite kontrole. `,
      `Ako se odlučite za nošenje kontaktnih leća specijalist 
      oftalmolog će vam nakon pregleda vida i pregleda oka 
      predložiti kontaktnu leću koja je optimalna za vas.<br><br>
      Koju kontaktnu leću odabrati mekanu ili polutvrdu ovisi 
      o građi oka, vašoj dioptriji, osobnim potrebama te 
      preporuci oftalmologa.<br><br>
      Nakon odabira optimalne kontaktne leće nudimo i 
      poduku o korištenju i održavanju vaših kontaktnih leća 
      te kasnije praćenje i redovite kontrole. `,
      `Osobe koje koriste računalo i/ili mobitele u 
      svakodnevnom životu često imaju tegobe uzrokovane 
      gledanjem u ekrane poput suhoće i zamora očiju te 
      glavobolja.<br><br>
      Preventivnim pregledom oka, određivanjem potrebne 
      dioptrije te propisivanjem optimalne korekcije 
      naočalama ili kontaktnim  kao i pridržavanjem 
      preporuka za rad na ekranima koje ćete dobiti od specijaliste oftalmologa nastanak tegobe se može 
      spriječiti ili barem odgoditi.`,
      `Glaukom je relativno česta očna bolest te predstavlja jedan od 
      vodećih uzroka sljepoće kod nas i u svijetu.<br><br>                    
      <strong>Čimbenici rizika</strong> za nastanak glaukoma su: <br>                
      1.Povišen očni tlak (normalne vrijednosti zdravog oka su  
      10-21 mmHg)<br>                                                               
      2.Starija dob (najčešće se javlja iza 40.g.života)<br>             
      3.Visoka kratkovidnost i dalekovidnost<br>                          
      4.Dijabetes, hipertenzija, migrena, upotreba 
      kortikosteroida, trauma<br>                                                   
      5.Glaukom u obitelji (nasljeđe - genetika) <br>
      Kod oboljelih obično <strong>povišen očni tlak</strong> dovodi do 
      oštećenja vidnog živca te propadanja vidnog polja. No 
      neke osobe oštećenja vidnog živca razvijaju i pri 
      normalnom očnom tlaku, pa tada govorimo o 
      <strong>glaukomu normalnog tlaka.</strong><br><br>
      Kod <strong>kroničnog glaukoma</strong> oboljeli u početnom stadiju 
      bolesti često nemaju <strong>nikakvih simptoma</strong>, zato ga 
      zovemo <strong>tihi kradljivac vida.</strong><br>
      Ako se bolest ne prepozna te napreduje dolazi prvo do 
      oštećenja perifernog vida pa oboljeli imaju osjećaj kao 
      da gledaju kroz tunel. Daljnjim napredovanjem bolesti 
      nepovratno se oštećuje centralni vid  i na kraju dolazi 
      do sljepoće.  <br><br>                                                                   
      <strong>Akutni glaukom</strong> je rjeđi, nastupa naglo i često je 
      popraćen glavoboljom, mučninom i povraćanjem. Uzrok mogu biti razni upalni procesi, trauma i genetska 
      predispozicija. Akutni glaukom je uvijek hitan slučaj. <br><br>
      <strong>Najsigurniji način ranog otkrivanja glaukoma je 
      detaljan oftalmološki pregled nakon kojeg slijedi 
      dodatna obrada:  </strong>                                                        <br>
      1.<strong>gonioskopija</strong> – dijagnostički postupak kojim se 
      procjenjuje očni kut i otkriva eventualna predispozicija 
      za nepravilno otjecanje očne vodice. <br>                             
      2.<strong>pahimetrija</strong> – mjerenje debljine rožnice je važno za pravilnu procjenu očnog tlaka.<br>          
      3.<strong>vidno polje</strong> - kompjutersko testiranje koje koristi 
      poseban glaukomski program kojim se detektiraju i 
      najmanja oštećenja u vidnom polju za svako oko 
      posebno.<br>
      4.<strong>OCT</strong> – radi kompjutersku analizu vidnog živca u sklopu kojeg 
      se mjeri debljina živčanih niti. Mjerenja vrši u 
      mikronima, jedinicama koje golim okom nisu vidljive.<br><br>   
      Osim ranog otkrivanja glaukoma, važno je i 
      pravilno liječenje te kontinuirano praćenje 
      oboljelih.`,
      `Poremećaj prepoznavanja boja je <strong>nasljedno 
      stanje</strong> koje češće pogađa muški spol. Najčešće je 
      problem u raspoznavanju zelene i crvene boje.<br><br> Većina 
      ljudi se dobro prilagodi svome poremećaju 
      prepoznavanja boja. No postoje <strong>zanimanja</strong> u kojima je 
      važno uredno ili sigurno raspoznavanje boja (piloti, 
      pomorci, profesionalni vozači, električari, zaštitari). <br><br>     
      U testiranju poremećaja koriste se najčešće <strong>Ishihara 
      tablice</strong> ( za otkrivanje  poremećaja ) i 
      <strong>anomaloskopija</strong> ( za procjenu težine poremaćaja ).<br><br> Ako tijekom života osjetite da boje raspoznajete 
      drugačije nego prije moguće je da se radi o <strong>stečenom 
      poremećaju</strong> uslijed bolesti oka.`,
      `<strong>KOMPJUTERIZIRANO VIDNO POLJE</strong> je uređaj 
      koji koristi različite programe kojima se detektiraju i 
      najmanja oštećenja u vidnom polju za svako oko 
      posebno. Predstavlja zlatni standard u dijagnostici 
      glaukoma. Koristi se u dijagnostici i drugih očnih i 
      neuroloških bolesti.`,
      `<strong>OCT -OPTIČKA KOHERENTNA TOMOGRAFIJA</strong> 
      je uređaj kojim se  obavlja snimanje mikroskopskih 
      slojeva mrežnice i vidnog živca te se dobije vrlo fini 
      prikaz slojeva pomoću laserske tehnologije.<br><br> 
      Visoka rezolucija uređaja omogućuju rano otkrivanje i 
      praćenje brojnih očnih bolesti poput: senilne 
      degeneracije makule, glaukoma,
      dijabetičke retinopatije, edema i ruptura makule, 
      epiretinalnih membrana i odignuća različitih slojeva 
      mrežnice. `,
      `- EPILACIJA TREPAVICA<br>
      - UKLANJANJE STRANOG TIJELA SPOJNICE<br>
      - UKLANJANJE STRANOG TIJELA ROŽNICE<br>
      - PROŠTRCAVANJE SUZNIH KANALA<br>
      - UKLANJANJE IZRASLINA NA VJEĐAMA`,
    ],
    doc: ["SILVIJA RADOČAJ PERKO,"],
    spec: ["dr.med., specijalist oftalmologije"],
  },

  {
    title: "Estetska medicina",
    description: `Za informacije i narudžbe  nazovite na <strong><a href="tel:+385993414555" style="text-decoration:none; color:#747474">(+385) 99 341 4555</a></strong>.`,
    newList: ["Konzultacijski pregled s izradom plana pomlađivanja"],
    drops: [
      "Dermalni fileri",
      "Botulinum toksin tip A",
      "Kolagen stimulatori",
      "Biorevitalizirajući preparati",
      "PRP tretmani za lice i vlasište",
      `Microneedling terapija (Dermapen 4<sup style="font-size:10px">TM</sup>)`,
      "Kombinacije tretmana - paketi",
    ],
    dropText: [
      `U ponudi pružamo visokokvalitetne dermalne filere različitih koncentracija
      hijaluronske kiseline koji su ciljano dizajnirani za različite tretmane i tretiranje različitih dijelova lica. Liječnik će prije aplikacije procijeniti potrebe svakog pojedinog klijenta i razviti personalizirani plan tretmana. Gel umrežene hijaluronske kiseline se primjenjuje u površinske ili dublje slojeve kože, djelujući na smanjenje sitnih linija i bora, popunjavanje dubokih bora, učvršćivanje konture lica te obnavljanje izgubljenog volumena.<br/></br>
      Dermalni fileri također mogu poboljšati estetiku usana, njihov oblik i konturu te pružiti dodatni volumen kako bi se postigla željena simetrija i forma.<br/></br>
      U Poliklinici Ercegović koristimo visokokvalitetne dermalne filere: <strong>Belotero</strong>, brenda Merz Aesthetics i <strong>Juvederm</strong> filere brenda Allergan.`,

      `Botulinum toksin tip A (Botox) je indiciran za tretiranje mimičnih bora gornjeg dijela lica, uključujući i bore na čelu između obrva ( bore mrštilice), bore oko očiju te vodoravne bore čela. Botox sprječava oslobađanje tvari zvane acetilkolin, koja je odgovorna za kontrakciju mišića. Kada mišići ne dobivaju signal da se kontrahiraju, privremeno se opuštaju. To rezultira smanjenjem vidljivosti mimičkih bora.Tipično, rezultati se primjećuju nakon nekoliko dana do tjedan dana od primjene, a vrhunac učinka obično se postiže u prva dva tjedna. Učinak trajanja efekta Botoxa je individualan, u rasponu od 3-5 mjeseci, nakon čega se mišići postupno vraćaju u svoje normalno stanje.<br/></br>
      Osim za uklanjanje bora, Botox se primjenjuje i u liječenju različitih neuroloških oboljenja kao što je migrena. Također se pokazao vrlo učinkovitim u rješavanju problema prekomjernog znojenja. O drugim mogućim indikacijama upotrebe Botoxa posavjetujte se s liječnikom.<br/></br>
      U Poliklinici Ercegović koristimo Botulin toxin tip A (<strong>Bocoture</strong>® brenda Merz
      Aesthetics i <strong>Vistabel</strong>, brenda Allergan).`,

      `<strong>Radiesse</strong><br/></br>
      Radiesse je injekcijsko punilo i biostimulator koji se sastoji od mikrosfera gela kalcijevog hidroksiapatita (CaHA) i karboksimetilceluloze. Radiesse ima jedinstveni trostruki učinak djelujući na stimulaciju kolagena i elastina za dugotrajno poboljšanje kvalitete kože uz povećanje elastičnosti i debljine kože, vertikalni lifting te bolje definiranje konture donjeg dijela lica i “jawline-a”. Trenutačni lifting učinak Radiesse filera povezan je s formiranjem novog kolagena procesima neokolageneze i neoelastogeneze. <br/>
      Tijekom vremena biostimulacija dovodi do nastanka nove kolagenske mreže koja zamjenjuje CaHA te se na taj način koža i konture obnavljaju sami iznutra. Mikrosfere Raddiessea u potpunosti su biorazgradive te se metaboliziraju prirodnim mehanizmima-fagocitozom. Tijekom tog procesa oslobađaju se ioni kalcija i fosfata koji su identični onima u našem tijelu. Znanstvene studije dokazuju da Radiesse ima brojne pozitivne učinke na kvalitetu kože pacijenata s rezultatima koji traju 12 mjeseci ili duže.<br/></br><strong>HarmonyCa</strong><br/></br>
      HArmonyCa je novi hibridni filer i biostimulator na tržištu, za poboljšanje strukture lica i kvalitete kože. ”HA“ u HArmonyCa™ označava hijaluronsku kiselinu, jedan od ključnih sastojaka ovog dermalnog filera. “Ca“ na kraju naziva označava kalcij, glavnu komponentu drugog ključnog sastojka, kalcijevog hidroksiapatita. Spajanjem ovih sastojaka u jednom proizvodu, Allergan je stvorio harmoniju ili ravnotežu, što se odražava u nazivu “HArmonyCa“. Jedna od izvrsnih stvari kod HArmonyCa™ je što možete vidjeti rezultate odmah nakon tretmana. Komponenta hijaluronske kiseline
      pruža trenutačni volumen i potporu koži, može zagladiti bore i linije te dati licu mlađi izgled. Međutim, prednosti komponente kalcijevog hidroksiapatita su postepenije.<br/>
      Kako proizvod potiče proizvodnju kolagena u koži, rezultati se nastavljaju
      poboljšavati tijekom vremena, rezultirajući čvršćom i zategnutijom kožom. Rezultati mogu trajati do 2 godine, ovisno o pojedincu i području tretmana.`,

      `<strong>Belotero Revive</strong><br/></br>
      Belotero Revive je visokokvalitetni skinbooster koji proizvodi renomirani njemački 
      brend Merz Aesthetics. Sastav ovog preparata temelji se na kombinaciji neumrežene
      hijaluronske kiseline te glicerola. Ova kombinacija čini kožu osvježenom, mekom i 
      elastičnom. Kroz sinergijsko djelovanje hijaluronske kiseline i glicerola, preparat 
      pomaže poboljšati tonus kože, smanjujući suhoću i umorni izgled. Rezultat je 
      revitalizirana koža koja zrači vitalnošću. Važno je napomenuti da proizvodi od strane 
      brenda Merz Aesthetics često prolaze kroz stroge kontrolne procese kako bi se 
      osigurala visoka učinkovitost i sigurnost. Belotero Revive predstavlja jedan od 
      njihovih proizvoda koji cilja na poboljšanje hidratacije i sjaja kože, čineći ga 
      popularnim izborom među onima koji traže visokokvalitetne preparate za njegu kože.<br/></br> 
      <strong>Profhilo</strong>,  proizvod švicarske kompanije IBSA DERMA,  ističe se svojom 
      inovativnom formulacijom termalno stabilizirane kombinacije hijaluronske kiseline 
      visoke i niske molekularne težine kako bi se postigli optimalni rezultati u 
      bioremodulaciji kože. Profhilo se aplicira BAP tehnikom (Bio Aestetic Points)  u 5 
      točaka na svakoj strani lica ili u 10 točaka na području vrata. Nakon injektiranja, 
      Profhilo se tijekom perioda od 4 tjedna polako širi u koži te stimulira 4 različite vrste 
      kolagena i elastina što rezultira poboljšanjem kvalitete kože. Također, potiče 
      povećanje masnih stanica i hidrira kožu zahvaljujući jednoj  od najviših koncentracija 
      hijaluronske kiseline (64 mg/2 ml) na tržištu.  To rezultira postepenim, prirodnim, 
      osvježenim izgledom, bez naglašenih promjena u volumenu. Profhilo se također 
      ističe svojom sposobnošću da poboljša teksturu kože, čineći je glađom i sjajnijom. 
      Preporučuje se napraviti dva Profhilo tretmana s razmakom od približno četiri tjedna 
      kako bi se postigli optimalni rezultati i potaknula prirodna proizvodnja kolagena. 
      Nakon početnih tretmana, održavanje se savjetuje 1-2x godišnje, prilagođeno 
      individualnim potrebama pacijenta i brzini razgradnje hijaluronske kiseline.<br/></br>
      <strong>Jalupro Super Hydro</strong>, švicarskog proizvođača  Professional Derma, jedinstven je 
      stimulator kolagena na nivou potpornih ligamenata koji sadrži jednu od najvećih 
      koncentracija hijaluronske kiseline na tržištu te pruža cjelovito rješenje  zahvaljujući 
      sinergijskom učinku između hijaluronske kiseline i aminokiselina. Sadrži 80 mg 
      hibridne hijaluronske kiseline visoke i niske molekularne težine. Ova struktura ima 
      snažan lifting učinak i hidrataciju. Sadrži 7 različitih aminokiselina: glicin, prolin, lizin, 
      leucin + alanin, valin, arginin. Svojom bogatom kombinacijom aminokiselina 
      podržava i jača sintezu kolagena i elastina  koji jačaju potporne ligamente i daju 
      čvrstinu koži. <br/>
      Također s peptidnim molekulama kao što su acetil dekapeptid 3, acetil tetrapeptid 5, 
      oligopeptid 24 omogućuje dugotrajnu aktivaciju fibroblasta. Blagotvoran je svojim 
      usporavajućim i regenerativnim djelovanjem kod degenerativnih promjena kolagena i
      kože. Injektira  se tankim iglicama u 6 točaka na svakoj strani lica i u jednu točku na 
      bradi ili u 12 određenih točaka na vratu. Preporučuje se napraviti dva tretmana s 
      razmakom od mjesec dana.<br/></br>
      <strong>PhilArt polinukleotidi</strong>,  proizvođača Croma pharma predstavljaju inovativni 
      skinbooster koji nudi široku paletu proizvoda za unapređenje stanja kože. Cilj im je 
      hidratizirati kožu na licu, vratu, čelu, rukama, dekolteu, periokularnom području te 
      smanjiti površinske bore i ožiljke. Također, namijenjen je i vlasištu radi poticanja 
      rasta kose. Infiltracija polinukleotida u kožu potiče hidrataciju, elastičnost i prirodnu 
      regeneraciju kože te također optimizira i priprema  kožu pacijenta za poboljšanje 
      rezultata drugih estetskih tretmana. Tehnologija visoke pročišćenosti polinukleotida 
      (PN-HPT) imaju dokazanu sigurnost i djelotvornost. Polinukleotidi ne samo da potiču
      aktivnost fibroblasta i proizvodnju kolagena, već i smanjuju slobodne radikale u koži, 
      poboljšavajući kvalitetu oštećene i atrofične kože. U ponudi imamo <strong>PhilArt</strong> (PN-HPT 
      40 mg/2 ml), <strong>PhilArt eyes</strong> (PN-HPT 15 mg/2 ml), <strong>Philart hair</strong> (PN-HPT15 mg/2 ml)  
      i <strong>Philart next</strong> (PN-HPT 20mg/ 2 ml +hijaluronska kiselina 20mg/2 ml). Preporučuje 
      se napraviti 3-4 tretmana u razmaku od  2-3 tjedna.<br/></br>
      <strong>Tretman egzosomima</strong><br/></br>
      Egzozomska terapija trenutno je jedna od najtraženijih metoda pomlađivanja u 
      regenerativnoj medicini. Egzosomi, mikrovezikule slične mjehurićima, sadrže RNA, 
      proteine i lipide, prenoseći ih između stanica putem tjelesnih tekućina ili krvotoka. 
      Njihova osnovna uloga leži u međustaničnoj komunikaciji. Terapija egzosomima 
      prilagođena je svim tipovima kože, pružajući protuupalne učinke, potičući 
      proizvodnju kolagena, poboljšava tonus kože, jača kožnu barijeru, potiče 
      regeneraciju, umanjujući bore, ožiljke i pore i umanjujući hiperpigmentacije. 
      Preporuča se korištenje najmanje tri tretmana, a kako bi se postigli maksimalni 
      klinički rezultati, preporučuje se izvođenje do pet tretmana. Između prva tri tretmana 
      preporučuje se razmak od dva tjedna, dok se između četvrtog i petog tretmana 
      preporučuje razmak od četiri tjedna. <br/></br>
      <strong>Hyamino Eyes</strong><br/></br>
      Hyamino Eyes je specijalizirani proizvod dizajniran za rješavanje 
      nesavršenosti na osjetljivom području oko očiju. Ova jedinstvena 
      formulacija kombinira snagu hijaluronske kiseline (10 mg/ml), peptida i 
      vitamina kako bi ciljala na specifične probleme poput tamnih krugova, 
      vrećica ispod očiju i znakova starenja. Hyamino Eyes sa svojim učinkom 
      drenaže pomaže umanjiti pojavu vrećica ispod očiju, pružajući osvježen i 
      revitaliziran izgled. Potiče mikrocirkulaciju, pomažući smanjenju pojave 
      tamnih krugova oko očiju, poboljšava ukupan izgled kože oko očiju 
      ublažavajući  sitne linije i bore, podržava proizvodnju kolagena i elastina 
      te  poboljšava teksturu i sjaj kože. Preporučeni protokol je napraviti 3 
      tretmana u intervalima od 3 tjedna. Za održavanje rezultata se preporuča 
      napraviti 1 tretman  svaka 3 mjeseca. Protokol i koncentracija mogu se 
      prilagoditi ovisno o specifičnim potrebama kože.<br/></br>
      <strong>RRS HA eyes mezoterapija</strong><br/></br>
      U tretiranju bora oko očiju i tamnih podočnjaka primjenjujemo <strong>RRS HA eyes</strong> 
      mezoterapiju koja uz neumreženu hijaluronsku kiselinu sadrži vitamine, peptide i 
      antioksidanse. RRS eyes smanjuje pojavu otečenih očiju, zaglađuje konturu oka, 
      pruža blagi posvjetljujući učinak i  poboljšava elastičnost kože. Preporuka je napraviti
      4 tretmana s razmakom od 2 tjedna. Preporuča se ponoviti protokol 1-3 puta 
      godišnje.<br/></br>
      `,

      `Terapija pomlađivanja lica krvnom plazmom, poznat i kao PRP tretman (platelet-rich 
      plasma) jedan  je  od najizvođenijih zahvata koji koristi moć liječenja trombocita i 
      faktora rasta iz krvi pacijenta. Nakon uzimanja uzorka krvi, liječnikr će koristeći 
      centrifugu s visokim brojem okretaja odvojiti plazmu od preostalih komponenti krvi. 
      Na taj način dobiva se krvna plazma obogaćena trombocitima koji sadrže faktore 
      rasta i potiče se formiranje novog kolagena. Rezultat je osvježen izgled lica i 
      pomlađena koža, smanjena vidljivost bora i linija te umanjeni ožiljci od akni.<br/></br>
      PRP terapija vlastitom krvlju izvodi se i na vlasištu, za poticanja rasta kose. PRP 
      metodom stimuliramo rast kose te većom prokrvljenošću vlasišta potičemo njezin 
      ubrzani rast. <br/></br>
      Tretman je prirodan i siguran, bez štetnih učinaka. Efekti PRP tretmana mogu biti 
      postepeni, pa je često potrebna serija tretmana kako bi se postigao željeni rezultat.`,

      `Microneedling je inovativna tehnika koja se temelji na principu kontroliranog 
      stvaranja mikrotrauma u koži te postaje nezaobilazna metoda za postizanje sjajne i 
      zdrave kože. U središtu ovog revolucionarnog pristupa ljepoti nalazi se Dermapen 4, 
      vrhunski uređaj koji donosi preciznost, prilagodljivost i impresivne rezultate u 
      području mikroiglične terapije stvarajući 1.920 uboda u sekundi što ga čini najbržim 
      microneedling tretmanom na svijetu. Mikroiglice tijekom tretmana prodiru u kožu 
      stvarajući sitne mikrokanale kojima se može  unijeti i do 80% više topikalnih hranjivih
      tvari dublje u kožu. Stvaranjem kontroliranih mikrotrauma potiče se proizvodnja 
      kolagena i elastina, ključnih elemenata za održavanje elastičnosti i mladolikosti kože.
      Ovaj pristup nudi širok spektar prednosti, uključujući smanjenje bora, poboljšanje 
      teksture kože, smanjenje ožiljaka i hiperpigmentacija i dr. Dermapen 4 donosi 
      sigurnost i učinkovitost, prilagođavajući se različitim potrebama i tipovima kože.<br/></br> 
      Tijekom ovog tretmana koristimo proizvode Dp Dermaceuticals, specijalno razvijene 
      za upotrebu zajedno s Dermapenom. Ovisno o indikacijama i stanju kože, tretman 
      Dermapenom trebalo bi ponoviti više puta.`,

      `Radiesse 1.5 ml + Jalupro Superhydro 2.5 ml<br/><br/>
      Radiesse 1.5 ml + Belotero Revive 2 ml<br/><br/>
      PRP + Jalupro Superhydro 2.5 ml<br/><br/>
      PRP + Belotero Revive 2 ml<br/><br/>
      Bocouture 50 IJ + Radiesse a 1.5 ml + 1 ml dermalni filer (Belotero ili Juvederm)<br/><br/>
      Bocouture 50 IJ + Radiesse a 1.5 ml + 1 ml LOVE UP DF<br/><br/>
      Mesosynergy (kombinirani tretman kemijskog pilinga, birevitalizatora i mesoneedlinga)`,
    ],
    doc: ["doc.dr.sc. ZLATKO KLJAJIĆ, dr. med.", "BRIGITA ERCEGOVIĆ, dr.med."],
    spec: [
      "specijalist ORL,Merz Key Opinion Leader (KOL)",
      "specijalist dermatologije i venerologije",
    ],
    relatedTitle: "Dermatologija i venerologija",
    relatedDescription: `Za informacije i narudžbe  nazovite na <strong><a href="tel:+385993414555" style="text-decoration:none; color:#747474">(+385) 99 341 4555</a></strong>.`,
    relatedList: ["Dermatološki pregled", "Dermatoskopski pregled madeža"],
    relatedDoc: ["BRIGITA ERCEGOVIĆ, dr.med."],
    relatedSpec: ["specijalist dermatologije i venerologije"],
    novo: true,
  },
];
