import React from "react";
import styles from "./PartnerOrganizations.module.css";
import GraweLogo from "../../Assets/GraweLogo.png";
import AllianzLogo from "../../Assets/AllianzLogo.png";
import HzzoLogo from "../../Assets/HZZOLogo.png";
import MerkurLogo from "../../Assets/MerkurLogo.png";
import UniqaLogo from "../../Assets/UniqaLogo.png";
import CroatiaOsiguranjeLogo from "../../Assets/CroatiaOsiguranjeLogo.png";
import wiener from "../../Assets/wiener.png";

const organizationLogos = [
  HzzoLogo,
  UniqaLogo,
  GraweLogo,
  CroatiaOsiguranjeLogo,
  MerkurLogo,
  AllianzLogo,
];

const PartnerOrganizations: React.FC = () => {
  return (
    <div className={styles["partner-organizations"]}>
      <div className={styles["header-row"]}>
        <h1 className={styles["heading"]}>PARTNERSKE ORGANIZACIJE</h1>
      </div>
      <div className={styles["logo-row"]}>
        {organizationLogos.map((logo, index) => (
          <div key={index} className={styles.organization}>
            <img src={logo} alt={`Organizations Logo ${index + 1}`} />
          </div>
        ))}
        <img src={wiener} alt="Organizations Logo" className={styles.wiener} />
      </div>
    </div>
  );
};

export default PartnerOrganizations;
