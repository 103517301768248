import React from "react";
import styles from "./Footer.module.css";
import logo from "../../Assets/LogoWhite.png";
import facebookIcon from "../../Assets/FacebookIcon.png";
import instagramIcon from "../../Assets/InstagramIcon.png";
import { Link as ScrollLink } from "react-scroll";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <div className={styles.logoImg}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.socialIcons}>
          <a
            className={styles.facebookIconLink}
            href="https://www.facebook.com/profile.php?id=61556240980994"
          >
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a
            className={styles.instagramIconLink}
            href="https://www.instagram.com/poliklinika_ercegovic/"
          >
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
        <div className={styles.leftScolumn}>
          <h1 className={styles.largeHeader}>Poveznice</h1>
          <div>
            {location.pathname === "/" || location.pathname === "" ? (
              <ScrollLink
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
                className={styles.link}
              >
                Kontaktiraj nas
              </ScrollLink>
            ) : (
              <a href="/" className={styles.link}>
                Kontaktiraj nas
              </a>
            )}
          </div>
          <div>
            <a href="/cookie" className={styles.link}>
              Upravljanje kolačićima
            </a>
          </div>
        </div>
        <div className={styles.rightScolumn}>
          <h1 className={styles.largeHeader}>Poliklinika</h1>
          <div>
            {location.pathname === "/" || location.pathname === "" ? (
              <ScrollLink
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
                className={styles.link}
              >
                O nama
              </ScrollLink>
            ) : (
              <a href="/" className={styles.link}>
                O nama
              </a>
            )}
          </div>
          <div>
            {location.pathname === "/" || location.pathname === "" ? (
              <ScrollLink
                to="departments"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
                className={styles.link}
              >
                Djelatnosti i usluge
              </ScrollLink>
            ) : (
              <a href="/" className={styles.link}>
                Djelatnosti i usluge
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.Copyrights}>
        © Poliklinika Ercegović 2023. Sva prava pridržana.
      </div>
    </footer>
  );
};

export default Footer;
