// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Natrag_backimg__tsYNP {
  height: 24px;
  width: 24px;
}

.Natrag_back__0zRlQ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  padding-bottom: 20px;
  width: 200px;
}

.Natrag_backtext__gQNS7 {
  font-family: "Open Sans";
  font-weight: 700;
  margin: auto 5px;
  padding-bottom: 2px;
  color: #9fa7b2;
  width: 150px;
}

.Natrag_link__YSlAG {
  text-decoration: none;
}

.Natrag_backtext__gQNS7:hover,
.Natrag_backimg__tsYNP:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Natrag/Natrag.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,eAAe;AACjB","sourcesContent":[".backimg {\n  height: 24px;\n  width: 24px;\n}\n\n.back {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  height: 24px;\n  padding-bottom: 20px;\n  width: 200px;\n}\n\n.backtext {\n  font-family: \"Open Sans\";\n  font-weight: 700;\n  margin: auto 5px;\n  padding-bottom: 2px;\n  color: #9fa7b2;\n  width: 150px;\n}\n\n.link {\n  text-decoration: none;\n}\n\n.backtext:hover,\n.backimg:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backimg": `Natrag_backimg__tsYNP`,
	"back": `Natrag_back__0zRlQ`,
	"backtext": `Natrag_backtext__gQNS7`,
	"link": `Natrag_link__YSlAG`
};
export default ___CSS_LOADER_EXPORT___;
