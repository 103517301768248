import styles from "./AkcijeINovostiPage.module.css";
import Natrag from "../../Components/Natrag/Natrag";
import AkcijeINovosti from "../../Components/AkcijeINovostiCard/AkcijeINovosti";
import { AkcijeData } from "../../Components/AkcijeINovostiCard/AkcijeData";
import { NovostiData } from "../../Components/AkcijeINovostiCard/NovostiData";
import { useEffect, useState } from "react";

export default function AkcijePage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [akcije, setAkcije] = useState(true);
  const [novosti, setNovosti] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    if (windowWidth < 1280) {
      if (akcije) {
        setNovosti(false);
      } else {
        setAkcije(false);
        setNovosti(true);
      }
    } else {
      setAkcije(true);
      setNovosti(true);
    }
  });
  return (
    <div className={styles.wrapper}>
      <Natrag />
      <h1 className={styles.h1}>Akcije i novosti</h1>
      <div className={windowWidth < 1200 ? styles.btns : styles.hidden}>
        <button
          className={akcije ? styles.btn : styles.inactive}
          onClick={() => {
            setAkcije(true);
            setNovosti(false);
          }}
        >
          Akcije
        </button>
        <button
          className={novosti ? styles.btn : styles.inactive}
          onClick={() => {
            setAkcije(false);
            setNovosti(true);
          }}
        >
          Novosti
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.akcije}>
          <h3 className={akcije && novosti ? styles.h3 : styles.hidden}>
            Akcije
          </h3>

          <div className={akcije ? styles.popusti : styles.hidden}>
            {AkcijeData.map((item, index) => (
              <div className={styles.items} key={index}>
                <AkcijeINovosti
                  date={item.date}
                  title={item.title}
                  image={item.image}
                  link={`/akcije/${index + 1}`}
                  width={"230px"}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.novosti}>
          <h3 className={akcije && novosti ? styles.h3 : styles.hidden}>
            Novosti
          </h3>

          <div className={novosti ? styles.novo : styles.hidden}>
            {NovostiData.map((item, index) => (
              <div className={styles.items} key={index}>
                <AkcijeINovosti
                  date={item.date}
                  title={item.title}
                  image={item.image}
                  link={`/novosti/${index + 1}`}
                  width={"300px"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
