import styles from "./Akcije.module.css";
import { AkcijeData } from "../../Components/AkcijeINovostiCard/AkcijeData";
import { NovostiData } from "../../Components/AkcijeINovostiCard/NovostiData";
import { useNavigate, useParams } from "react-router-dom";
import backImg from "../../Assets/backImg.png";

interface AkcijaProps {
  akcija: boolean;
}

export default function Akcije({ akcija }: AkcijaProps) {
  const navigate = useNavigate();
  const { id } = useParams();
  const numId = parseInt(id!, 10);

  return (
    <div className={styles.wrapper}>
      <div className={styles.back} onClick={() => navigate("/akcijeinovosti")}>
        <img src={backImg} alt="" className={styles.backimg} />
        <p className={styles.backtext}>Natrag</p>
      </div>
      {akcija === true ? (
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.text}>
              <h1 className={styles.h1}>{AkcijeData[numId - 1].title}</h1>
              {AkcijeData[numId - 1].subtitle && (
                <h2 className={styles.h2}>{AkcijeData[numId - 1].subtitle}</h2>
              )}
              {AkcijeData[numId - 1].description.map((item, index) => (
                <p className={styles.p} key={index}>
                  {item}
                </p>
              ))}
              <div
                dangerouslySetInnerHTML={{
                  __html: AkcijeData[numId - 1].contact
                    ? `<h3 class="${styles.h3}">${
                        AkcijeData[numId - 1].contact
                      }</h3>`
                    : "",
                }}
              ></div>
            </div>
          </div>
          <div className={styles.imgContainer}>
            <img
              src={`../${AkcijeData[numId - 1].image}`}
              alt=""
              className={styles.img}
            />
          </div>
        </div>
      ) : (
        <div className={styles.containerNovosti}>
          <div className={styles.leftNovosti}>
            <h1 className={styles.h1}>{NovostiData[numId - 1].title}</h1>
            {NovostiData[numId - 1].description.map((item, index) => (
              <p
                key={index}
                className={styles.p}
                dangerouslySetInnerHTML={{ __html: item }}
              ></p>
            ))}
          </div>
          <div className={styles.imgContainerNovosti}>
            <img
              src={`../${NovostiData[numId - 1].image}`}
              alt=""
              className={styles.imgNovosti}
            />
          </div>
        </div>
      )}
    </div>
  );
}
