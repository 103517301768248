import React from "react";
import styles from "./AboutUs.module.css";
import img1 from "../../Assets/HomePageImgs/img1.png";
import img2 from "../../Assets/HomePageImgs/img2.png";
import img3 from "../../Assets/HomePageImgs/img3.png";
import img4 from "../../Assets/HomePageImgs/img4.png";
import arrow from "../../Assets/arrow.png";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <h1 className={styles.h1}>O poliklinici</h1>
        <p className={styles.text}>
          Poliklinika Ercegović već dugi niz godina obavlja usluge medicinskih
          pregleda, a zahvaljujući timu predanih i stručnih zaposlenika, naši
          klijenti se uvijek rado vraćaju.
        </p>
        <p className={styles.text}>
          Postojimo od 2004. godine i konstantno ulažemo napore kako bi za
          svakog klijenta osigurali vrhunsku uslugu.
        </p>

        <p className={styles.text}>
          Uspostavili smo sustav upravljanja kvalitetom prema{" "}
          <b>ISO 9001:2015</b>
        </p>
        <p className={styles.text}>
          U poliklinici se ponosimo svojom posvećenošću inkluzivnosti i pružanju
          kvalitetne zdravstvene usluge svima. S posebnim zadovoljstvom ističemo
          da imamo osposobljenog stručnjaka za znakovni jezik gluhih, nagluhih i
          gluhoslijepih osoba koji će omogućiti besprijekornu komunikaciju.
        </p>
        <p className={styles.text}>
          Nudimo personaliziranu medicinsku skrb, stručne liječnike i medicinsko
          osoblje te suvremenu opremu. Obratite nam se s punim povjerenjem i
          rezervirajte termin već danas.
        </p>
        <Link to="/tim">
          <button className={styles.btn}>
            <span className={styles.btnText}>
              UPOZNAJ NAŠ TIM
              <img src={arrow} alt="" className={styles.arrow} />
            </span>
          </button>
        </Link>
      </div>
      <div className={styles.right}>
        <div className={styles.griditem1}>
          <img src={img1} alt="" />
        </div>
        <div className={styles.griditem2}>
          <img src={img3} alt="" />
        </div>
        <div className={styles.griditem3}>
          <img src={img4} alt="" />
        </div>
        <div className={styles.griditem4}>
          <img src={img2} alt="" />
        </div>
      </div>
    </div>
  );
}
