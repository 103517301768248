import React from "react";
import styles from "./FindUs.module.css";
import { FindUsData, FindUsMaps } from "./FindUsData";
import FindUsText from "./FindUsCards/FindUsText";
import FindUsGrid from "./FindUsCards/FindUsGrid";

export interface Img {
  width: string[];
  url: string;
}
export interface FindUsProps {
  id: number;
  info: boolean;
  img: Img[];
}

export default function FindUs(props: FindUsProps) {
  const data = FindUsData[props.id];
  const map = FindUsMaps[props.id].url;
  const img = props.img;

  return (
    <div className={`${styles.wrapper} ${styles[`position${props.id}`]}`}>
      <div className={styles.title}>
        <h3 className={styles.h3}>KONTAKT</h3>
        <h1 className={styles.h1}>{data.title}</h1>
      </div>
      <div className={styles.left}>
        <FindUsText
          heading1={data.heading1}
          info1={data.info1}
          address={data.address}
          email={data.email}
          heading2={props.info ? data.heading2 : undefined}
          info2={props.info ? data.info2 : undefined}
        />
      </div>
      <div className={styles.right}>
        <FindUsGrid map={map} img={img} />
      </div>
    </div>
  );
}
