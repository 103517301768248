import React from "react";
import styles from "./MedicinaRadaCard.module.css";
import { IMedicina } from "../MedicinaRadaData";

export const MedicinaRadaCard: React.FC<IMedicina> = ({ image, title }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default MedicinaRadaCard;
