import styles from "./FormPage.module.css";
import Natrag from "../../Components/Natrag/Natrag";
import Form from "../../Components/Form/Form";
import Succes from "../../Components/Form/Success";

interface FormProps {
  id: number;
}

export default function FormPage({ id }: FormProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.back}>
        <Natrag />
      </div>
      <div className={styles.formContainer}>
        {id === 0 ? (
          <Form />
        ) : id === 1 ? (
          <Succes success={true} />
        ) : (
          <Succes success={false} />
        )}
      </div>
    </div>
  );
}
