import styles from "./Form.module.css";
import successImg from "../../Assets/success.png";
import errorImg from "../../Assets/error.png";

interface FormProps {
  success: boolean;
}

export default function FormCard({ success }: FormProps) {
  return (
    <>
      {success === true && (
        <div className={styles.content}>
          <img src={successImg} alt="" className={styles.success} />
          <h1 className={styles.h2}>Tvoj zahtjev za termin uspješno poslan!</h1>
          <p className={styles.p}>
            Hvala ti na poslanom zahtjevu. Naše osoblje će se potruditi
            odgovoriti u što kraćem mogućem roku.
          </p>
        </div>
      )}
      {success === false && (
        <div className={styles.content}>
          <img src={errorImg} alt="" className={styles.success} />
          <h1 className={styles.h2}>Greška prilikom slanja zahtjeva</h1>
          <p className={styles.p}>
            Molimo te da provjeriš svoju internetsku vezu ili pokušaš ponovno
            kasnije.
          </p>
        </div>
      )}
    </>
  );
}
