import React from "react";
import styles from "./NewsCard.module.css";

interface NewsProps {
  title: string;
  text: string[];
  link: string;
}

function NewsCard({ title, text, link }: NewsProps) {
  return (
    <>
      <p className={styles.h1}>{title}</p>
      <ul className={styles.list}>
        {text.map((item, index) => item && <li key={index}>{item}</li>)}
      </ul>
      <a className={styles.links} href={link}>
        Pročitaj više
      </a>
    </>
  );
}

export default NewsCard;
