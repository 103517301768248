import styles from "./NasiOdjeli.module.css";
import { Odjeli } from "./Odjeli";
import ServicesCard from "../../../../Components/ServicesCard/ServicesCard";

function NasiOdjeli() {
  return (
    <div className={styles.wrapper}>
      <p className={styles.h4}>DJELATNOSTI I USLUGE</p>
      <p className={styles.h2}>Pregledaj dostupne usluge po odjelima</p>
      <div className={styles.grid}>
        {Odjeli.map((item, index) => (
          <ServicesCard
            title={item.title}
            label={item.label}
            key={index}
            id={index}
          />
        ))}
      </div>
    </div>
  );
}

export default NasiOdjeli;
