import styles from "./Tim.module.css";
import Natrag from "../../Components/Natrag/Natrag";
import { TimData } from "./TimData";

export default function Tim() {
  return (
    <div className={styles.wrapper}>
      <Natrag />
      <div className={styles.title}>
        <h1 className={styles.h1}>Naš tim</h1>
        <p className={styles.p}>
          Svi članovi našeg tima kontinuirano unaprijeđuju svoje znanje i
          iskustvo kako bi svojim klijentima pružili najbolju uslugu.
        </p>
      </div>
      <div className={styles.grid}>
        {TimData.map((item, index) => (
          <div className={styles.doc} key={index}>
            <h2 className={styles.h2}>{item.name}</h2>
            <p className={styles.spec}>{item.spec}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
