// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_wrapper__37nBc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  gap: 48px;
  overflow-x: hidden;
}

.HomePage_section__haEOF {
  width: 90%;
}

.HomePage_kontakt__WRCdr {
  padding-top: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,uBAAuB;EACvB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  min-height: 100vh;\n  background-color: white;\n  gap: 48px;\n  overflow-x: hidden;\n}\n\n.section {\n  width: 90%;\n}\n\n.kontakt {\n  padding-top: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HomePage_wrapper__37nBc`,
	"section": `HomePage_section__haEOF`,
	"kontakt": `HomePage_kontakt__WRCdr`
};
export default ___CSS_LOADER_EXPORT___;
