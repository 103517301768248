// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MedicinaRada_wrapper__UQSWS {
  width: 80%;
  padding: 5%;
}

.MedicinaRada_h1__XElt7 {
  font-size: 36px;
  font-family: "Lato";
  font-weight: 800;
}

.MedicinaRada_p__YE12r {
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #747474;
  margin-bottom: 30px;
}

.MedicinaRada_backtext__Ll6Ks {
  font-family: "Open Sans";
  font-weight: 700;
  margin: auto 5px;
  padding-bottom: 2px;
  color: #9fa7b2;
}

.MedicinaRada_backtext__Ll6Ks:hover,
.MedicinaRada_backimg__\\+cBbm:hover {
  cursor: pointer;
}

.MedicinaRada_back__j6U5i {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  padding-bottom: 20px;
}

.MedicinaRada_text__5l8T3 {
  width: 550px;
  padding-bottom: 30px;
  padding-left: 80px;
}

.MedicinaRada_backimg__\\+cBbm {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 750px) {
  .MedicinaRada_wrapper__UQSWS {
    padding: 50px 20px;
  }
  .MedicinaRada_text__5l8T3 {
    padding-left: 60px;
    width: 80%;
  }
}

@media screen and (max-width: 650px) {
  .MedicinaRada_text__5l8T3 {
    padding-left: 40px;
  }
  .MedicinaRada_h1__XElt7 {
    font-size: 32px;
  }
}

@media screen and (max-width: 410px) {
  .MedicinaRada_wrapper__UQSWS {
    padding-left: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/MedicinaRadaPage/MedicinaRada.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  width: 80%;\n  padding: 5%;\n}\n\n.h1 {\n  font-size: 36px;\n  font-family: \"Lato\";\n  font-weight: 800;\n}\n\n.p {\n  font-size: 16px;\n  font-family: \"Open Sans\";\n  font-weight: 400;\n  color: #747474;\n  margin-bottom: 30px;\n}\n\n.backtext {\n  font-family: \"Open Sans\";\n  font-weight: 700;\n  margin: auto 5px;\n  padding-bottom: 2px;\n  color: #9fa7b2;\n}\n\n.backtext:hover,\n.backimg:hover {\n  cursor: pointer;\n}\n\n.back {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  height: 24px;\n  padding-bottom: 20px;\n}\n\n.text {\n  width: 550px;\n  padding-bottom: 30px;\n  padding-left: 80px;\n}\n\n.backimg {\n  height: 24px;\n  width: 24px;\n}\n\n@media screen and (max-width: 750px) {\n  .wrapper {\n    padding: 50px 20px;\n  }\n  .text {\n    padding-left: 60px;\n    width: 80%;\n  }\n}\n\n@media screen and (max-width: 650px) {\n  .text {\n    padding-left: 40px;\n  }\n  .h1 {\n    font-size: 32px;\n  }\n}\n\n@media screen and (max-width: 410px) {\n  .wrapper {\n    padding-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MedicinaRada_wrapper__UQSWS`,
	"h1": `MedicinaRada_h1__XElt7`,
	"p": `MedicinaRada_p__YE12r`,
	"backtext": `MedicinaRada_backtext__Ll6Ks`,
	"backimg": `MedicinaRada_backimg__+cBbm`,
	"back": `MedicinaRada_back__j6U5i`,
	"text": `MedicinaRada_text__5l8T3`
};
export default ___CSS_LOADER_EXPORT___;
