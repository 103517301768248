export const Interna: string[] = [
  "Sub/specijalistički pregledi uz navedene pretrage te konzultacije za odrasle",
  "Predoperativni pregledi odraslih",
  "Sistematski pregledi za odrasle",
  "EKG - elektrokardiogram",
  "KMAT - kontinuirano mjerenje arterijskog tlaka",
  "Ultrazvuk površinskih struktura",
  "Ultrazvuk štitnjače",
  "Ultrazvuk slinovnica",
  "Ultrazvuk trbušnih organa",
  "Ultrazvuk testisa",
  "Ostalo",
];

export const Rad: string[] = [
  "Pregledi vozača amatera i profesionalaca",
  "Pregledi pomoraca",
  "Pregled radnika ",
  "Pregled sudskih vještaka",
  "Pregled za držanje i nošenje oružja",
  "Pregled zaštitara i čuvara",
  "Pregled vatogasaca",
  "Pregled za školovanje i rad u zoni ionizirajućeg zračenja",
  "Pregled za upis na škole i fakultete",
  "Profesionalna orijentacija i selekcija pri upisu u škole",
  "Pregledi sportaša",
  "Edukacija radnika",
  "Edukacija prve pomoći radnika na radu",
  "Psihološka testiranja",
  "Testiranje manualnih sposobnosti",
  "Ostalo",
];

export const Aero: string[] = [
  "Pregled za civilne, profesionalne i sportske pilote",
  "Pregled kontrolora leta",
  "Pregled za padobrance",
  "Pregled za pilote  parajedrilica i ovjesnih jedrilica",
  "Pregled za članove kabinske posade",
  "Procjena mentalnog zdravlja zrakoplovnog osoblja",
  "Ostalo",
];
export const Oftamologija: string[] = [
  "Kompletni oftalmološki pregled",
  "Pregledi za mekane i RGP kontaktne leće",
  "Dijagnostika i liječenje očnih bolesti,",
  "Anomaloskopija (testiranje kolornog vida)",
  "Ostalo",
];
export const Estetska: string[] = [
  "Konzultacijski pregledi s izradom plana pomlađivanja",
  "Dermalni fileri",
  "Botulinum toksin tip A",
  "Kolagen stimulatori",
  "Biorevitalizirajući preparati",
  "PRP tretmani za lice i vlasište",
  "Microneedling",
  "Kombinacije tretmana - paketi",
  "Ostalo",
];
export const Dermatologija: string[] = [
  "Dermatološki pregled",
  "Dermatoskopski pregled madeža",
  "Ostalo",
];
