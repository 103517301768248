// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsCard_list__9ICmC {
  list-style-type: none;
  padding-inline-start: 0px;
  width: 85%;
}

.NewsCard_links__q12OR,
.NewsCard_list__9ICmC li {
  color: white;
}

/* .list li {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */

.NewsCard_h1__vMn4S {
  font-family: "Open sans";
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 36px;
  max-width: 18ch;
  color: white;
  width: 100%;
}

.NewsCard_links__q12OR:hover {
  color: rgb(255, 255, 255, 0.8);
}

@media screen and (max-width: 650px) {
  .NewsCard_h1__vMn4S {
    font-size: 32px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/News/NewsCard/NewsCard.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,UAAU;AACZ;;AAEA;;EAEE,YAAY;AACd;;AAEA;;;;;GAKG;;AAEH;EACE,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".list {\n  list-style-type: none;\n  padding-inline-start: 0px;\n  width: 85%;\n}\n\n.links,\n.list li {\n  color: white;\n}\n\n/* .list li {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n} */\n\n.h1 {\n  font-family: \"Open sans\";\n  font-weight: 700;\n  letter-spacing: 0px;\n  font-size: 36px;\n  max-width: 18ch;\n  color: white;\n  width: 100%;\n}\n\n.links:hover {\n  color: rgb(255, 255, 255, 0.8);\n}\n\n@media screen and (max-width: 650px) {\n  .h1 {\n    font-size: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `NewsCard_list__9ICmC`,
	"links": `NewsCard_links__q12OR`,
	"h1": `NewsCard_h1__vMn4S`
};
export default ___CSS_LOADER_EXPORT___;
