// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AkcijeINovosti_wrapper__oUSnN {
  display: flex;
  gap: 20px;
}

.AkcijeINovosti_img__jktRo {
  height: 200px;
}

.AkcijeINovosti_date__0fovz {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  color: #bcbcbc;
}

.AkcijeINovosti_h2__YkfSi {
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: calc(3 * 1.2em);
  text-overflow: ellipsis;
  max-width: 24ch;
}

.AkcijeINovosti_p__bec2x {
  color: #090446;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  text-decoration: underline;
}

@media screen and (max-width: 850px) {
  .AkcijeINovosti_h2__YkfSi {
    font-size: 22px;
  }
}

@media screen and (max-width: 795px) {
  .AkcijeINovosti_img__jktRo {
    width: 230px;
  }
}

@media screen and (max-width: 620px) {
  .AkcijeINovosti_h2__YkfSi {
    font-size: 20px;
    width: 90%;
  }
}

@media screen and (max-width: 570px) {
  .AkcijeINovosti_img__jktRo {
    height: 170px;
    width: 200px;
  }
  .AkcijeINovosti_h2__YkfSi {
    font-size: 19px;
  }
  .AkcijeINovosti_text__BTimH {
    width: 40%;
  }
}

@media screen and (max-width: 410px) {
  .AkcijeINovosti_img__jktRo {
    width: 50%;
    height: 140px;
  }
  .AkcijeINovosti_text__BTimH {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/AkcijeINovostiCard/AkcijeINovosti.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,oBAAoB;EACpB,4BAA4B;EAC5B,gBAAgB;EAChB,qBAAqB;EACrB,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,eAAe;IACf,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;EACA;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;IACV,aAAa;EACf;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 20px;\n}\n\n.img {\n  height: 200px;\n}\n\n.date {\n  font-family: \"Open Sans\";\n  font-weight: 600;\n  font-size: 16px;\n  color: #bcbcbc;\n}\n\n.h2 {\n  font-size: 24px;\n  font-weight: 700;\n  font-family: \"Open Sans\";\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  -webkit-line-clamp: 2;\n  max-height: calc(3 * 1.2em);\n  text-overflow: ellipsis;\n  max-width: 24ch;\n}\n\n.p {\n  color: #090446;\n  font-size: 16px;\n  font-weight: 600;\n  font-family: \"Open Sans\";\n  text-decoration: underline;\n}\n\n@media screen and (max-width: 850px) {\n  .h2 {\n    font-size: 22px;\n  }\n}\n\n@media screen and (max-width: 795px) {\n  .img {\n    width: 230px;\n  }\n}\n\n@media screen and (max-width: 620px) {\n  .h2 {\n    font-size: 20px;\n    width: 90%;\n  }\n}\n\n@media screen and (max-width: 570px) {\n  .img {\n    height: 170px;\n    width: 200px;\n  }\n  .h2 {\n    font-size: 19px;\n  }\n  .text {\n    width: 40%;\n  }\n}\n\n@media screen and (max-width: 410px) {\n  .img {\n    width: 50%;\n    height: 140px;\n  }\n  .text {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AkcijeINovosti_wrapper__oUSnN`,
	"img": `AkcijeINovosti_img__jktRo`,
	"date": `AkcijeINovosti_date__0fovz`,
	"h2": `AkcijeINovosti_h2__YkfSi`,
	"p": `AkcijeINovosti_p__bec2x`,
	"text": `AkcijeINovosti_text__BTimH`
};
export default ___CSS_LOADER_EXPORT___;
