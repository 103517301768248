import styles from "./Odjeli.module.css";
import { OdjeliData } from "./OdjeliData";
import phone from "../../Assets/phone1.png";
import calendar from "../../Assets/calendar.png";
import crtica from "../../Assets/crtica.png";
import verify from "../../Assets/verify.png";
import avion from "../../Assets/avion.png";
import avion2 from "../../Assets/avion2.png";
import novo from "../../Assets/novo.png";
import DownArrow from "../../Assets/DownArrow.svg";
import UpArrow from "../../Assets/UpArrow.svg";
import { useParams } from "react-router-dom";
import Natrag from "../../Components/Natrag/Natrag";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const Odjeli: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const { id } = useParams();
  const numId = parseInt(id!, 10);

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <Natrag />
      <div className={styles.content}>
        <div className={styles.left}>
          {OdjeliData[numId - 1].plane === true ? (
            <div className={styles.plane}>
              <img src={avion} alt="" className={styles.planeimg} />
              <h1 className={styles.h1}>{OdjeliData[numId - 1].title}</h1>
            </div>
          ) : null}
          {OdjeliData[numId - 1].novo === true ? (
            <div className={styles.plane}>
              <img src={novo} alt="" className={styles.novo} />
              <h1 className={styles.h1}>{OdjeliData[numId - 1].title}</h1>
            </div>
          ) : (
            !OdjeliData[numId - 1].plane && (
              <h1 className={styles.h1}>{OdjeliData[numId - 1].title}</h1>
            )
          )}
          <p
            className={styles.p}
            dangerouslySetInnerHTML={{
              __html: OdjeliData[numId - 1].description,
            }}
          ></p>
          <ul className={styles.list}>
            {OdjeliData[numId - 1].newList?.map((item, index) => (
              <li key={index} className={styles.li}>
                <p className={`${styles.newListItem} ${styles.listp}`}>
                  {item}
                </p>
              </li>
            ))}
            {OdjeliData[numId - 1].list?.map((item, index) => (
              <li key={index} className={styles.li}>
                <img src={crtica} alt="" className={styles.crtica} />
                <p className={styles.listp}>{item}</p>
              </li>
            ))}
          </ul>
          <div className={styles.dropdownsContainer}>
            {OdjeliData[numId - 1].drops?.map((dropdown, index) => (
              <div key={index} className={styles.dropdownsContent}>
                <div
                  className={
                    openDropdown === index ? styles.open : styles.dropdown
                  }
                  onClick={() => handleClick(index)}
                >
                  <div dangerouslySetInnerHTML={{ __html: dropdown }} />
                  <img
                    src={openDropdown === index ? UpArrow : DownArrow}
                    className={styles.arrowDown}
                    alt=""
                  />
                </div>
                {openDropdown === index && (
                  <div className={styles.dropContent}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: OdjeliData[numId - 1].dropText![index],
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          {OdjeliData[numId - 1].doc?.map((item, index) => (
            <div className={styles.doc} key={index}>
              <h3 className={styles.h3}>{OdjeliData[numId - 1].doc![index]}</h3>
              <p className={styles.pDoc}>
                {OdjeliData[numId - 1].spec![index]}
              </p>
            </div>
          ))}
          {OdjeliData[numId - 1].btns === false ? (
            <div className={styles.planeAgency}>
              <p className={styles.pAgency}>
                Zrakoplovno-medicinski centar certificiran je od Hrvatske
                agencije za civilno zrakoplostvo:
              </p>
              <img src={avion2} alt="" className={styles.avimg} />
            </div>
          ) : (
            <>
              <button className={styles.btn1} onClick={() => navigate("/form")}>
                <img src={calendar} alt="" className={styles.btnimg1} />
                <span className={styles.btntext}>DOGOVORI TERMIN</span>
              </button>
              <button className={styles.btn2}>
                <img src={phone} alt="" className={styles.btnimg2} />
                <span className={styles.btntext}>NAZOVI ODMAH</span>
              </button>
            </>
          )}
        </div>
      </div>
      {/* Second component if exists */}
      {OdjeliData[numId - 1].relatedTitle && (
        <div className={styles.content}>
          <div className={styles.left}>
            {OdjeliData[numId - 1].plane === true ? (
              <div className={styles.plane}>
                <img src={avion} alt="" className={styles.planeimg} />
                <h1 className={styles.h1}>
                  {OdjeliData[numId - 1].relatedTitle}
                </h1>
              </div>
            ) : (
              <h1 className={styles.h1}>
                {OdjeliData[numId - 1].relatedTitle}
              </h1>
            )}
            <p
              className={styles.p}
              dangerouslySetInnerHTML={{
                __html: OdjeliData[numId - 1].relatedDescription!,
              }}
            ></p>
            <ul className={styles.list}>
              {OdjeliData[numId - 1].relatedList?.map((item, index) => (
                <li key={index} className={styles.li}>
                  <img src={crtica} alt="" className={styles.crtica} />
                  <p className={styles.listp}>{item}</p>
                </li>
              ))}
            </ul>
            {OdjeliData[numId - 1].docAvion?.map((item, index) => (
              <div className={styles.avion} key={index}>
                <img src={verify} alt="" className={styles.verify} />
                <p key={index} className={styles.pAvion}>
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.right}>
            {OdjeliData[numId - 1].relatedDoc?.map((item, index) => (
              <div className={styles.doc} key={index}>
                <h3 className={styles.h3}>
                  {OdjeliData[numId - 1].relatedDoc![index]}
                </h3>
                <p className={styles.pDoc}>
                  {OdjeliData[numId - 1].relatedSpec![index]}
                </p>
              </div>
            ))}
            <button className={styles.btn1} onClick={() => navigate("/form")}>
              <img src={calendar} alt="" className={styles.btnimg1} />
              <span className={styles.btntext}>DOGOVORI TERMIN</span>
            </button>
            <button className={styles.btn2}>
              <img src={phone} alt="" className={styles.btnimg2} />
              <span className={styles.btntext}>NAZOVI ODMAH</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
