import React from "react";
import styles from "./FindUsGrid.module.css";
import { Img } from "../FindUs";

interface FindUsGridProps {
  map: string;
  img: Img[];
}

const FindUsGrid: React.FunctionComponent<FindUsGridProps> = ({ map, img }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgwrapper}>
        {img.map((item, index) => (
          <img
            key={index}
            src={item.url}
            alt=""
            className={styles.img}
            width={item.width[index]}
          />
        ))}
      </div>
      {/* <iframe
        src={map}
        loading="lazy"
        title="Map"
        className={styles.map}
      ></iframe> */}
      <img src={map} alt="" className={styles.map} />
    </div>
  );
};

export default FindUsGrid;
