import React from "react";
import styles from "./FindUsText.module.css";
import icon1 from "../../../Assets/FindUsImgs/icon1.png";
import icon2 from "../../../Assets/FindUsImgs/icon2.png";
import icon3 from "../../../Assets/FindUsImgs/icon3.png";

interface TextProps {
  address: string;
  heading1: string;
  info1: string[];
  heading2?: string;
  info2?: string[];
  email: string;
}

export default function FindUsText({
  address,
  heading1,
  info1,
  heading2,
  info2,
  email,
}: TextProps) {
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };
  return (
    <div className={styles.textWrapper}>
      <h4 className={styles.h4}>
        <img src={icon1} alt="" className={styles.icon} />
        Adresa
      </h4>
      <p className={styles.p}>{address}</p>
      <h4 className={styles.h4}>
        <img src={icon2} alt="" className={styles.icon} />
        {heading1}
      </h4>
      <ul className={styles.list}>
        {info1.map(
          (item, index) =>
            item && (
              <li
                className={styles.p}
                key={index}
                dangerouslySetInnerHTML={createMarkup(item)}
              />
            )
        )}
      </ul>
      {heading2 && info2 && (
        <div>
          <h4 className={styles.sec}>{heading2}</h4>
          <ul className={styles.list}>
            {info2.map(
              (item, index) =>
                item && (
                  <li
                    className={styles.p}
                    key={index}
                    dangerouslySetInnerHTML={createMarkup(item)}
                  />
                )
            )}
          </ul>
        </div>
      )}
      <h4 className={styles.h4}>
        <img
          src={icon3}
          height={24}
          width={24}
          alt=""
          className={styles.icon}
        />
        Email adresa
      </h4>
      <p className={styles.p} dangerouslySetInnerHTML={{ __html: email }} />
    </div>
  );
}
