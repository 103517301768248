export type Data = {
  id: number;
  title: string;
  label: string;
};

export const Odjeli: Data[] = [
  {
    id: 0,
    title: "INTERNA MEDICINA",
    label: "",
  },
  {
    id: 1,
    title: "MEDICINA RADA I SPORTA",
    label: "",
  },
  {
    id: 2,
    title: "AEROMEDICAL CENTAR",
    label: "",
  },
  {
    id: 3,
    title: "OFTALMOLOGIJA",
    label: "",
  },
  {
    id: 4,
    title: "ESTETSKA MEDICINA/ DERMATOLOGIJA I VENEROLOGIJA ",
    label: "Novo",
  },
];
